<template>
    <div class="app-page">
      <div class="container">
        <h1>Post a Ride</h1>
        <!-- Section 1: Ride Information -->
        <div class="form-section">
          <h2>Ride Information</h2>
        <div>
      <div>
      <div class="label">
        <img class="label-picto" src="/images/placeholder.png"/>
        <label class="label-label" for="startLocation">Start Location:</label>
      </div>
      <input
          class="label-input"
          v-model="startLocation" 
          type="text" 
          id="startLocation" 
          @input="getSuggestions('start')" 
          placeholder="Type to search..." 
          required 
        />
        <ul v-if="suggestions.start.length > 0" class="suggestions">
          <li class="suggestion-item" v-for="(suggestion, index) in suggestions.start" :key="index" @click="selectSuggestion('start', suggestion)">
            {{ getNiceSuggestion(suggestion) }}
          </li>
        </ul>
      </div>
      <div>
        <div class="label">
          <img class="label-picto" src="/images/flag.png"/>
          <label class="label-label" for="endLocation">End Location:</label>
        </div>
        <input 
          v-model="endLocation" 
          type="text" 
          id="endLocation" 
          @input="getSuggestions('end')" 
          placeholder="Type to search..." 
          required 
        />
        <ul v-if="suggestions.end.length > 0" class="suggestions">
          <li class="suggestion-item" v-for="(suggestion, index) in suggestions.end" :key="index" @click="selectSuggestion('end', suggestion)">
            {{ getNiceSuggestion(suggestion) }}
          </li>
        </ul>
      </div>
      <div v-for="(stopLocation, index) in stopLocations" :key="index">
        <div class="label">
          <img class="label-picto" src="/images/location.png"/>
          <label class="label-label" for="stopLoaciton">Enter Stop {{ index+1 }} Location:</label>
        </div>
        <table>
          <tr>
            <td>
              <input
                type='text'
                v-model="stopLocations[index]" 
                @input="getSuggestions('stop', index)"
                placeholder="Enter Stop Location"
              />
            </td>
            <td>
              <button class="btn btn--primary" @click="removeStopLocation(index)">X</button>
            </td>
          </tr>
        </table>
      <ul v-if="stopSuggestions[index]?.length"  class="suggestions">

        
        <li class="suggestion-item" v-for="suggestion in stopSuggestions[index]" :key="suggestion.place_id" @click="selectLocation('stop', suggestion, index)">
          
          {{ getNiceSuggestion(suggestion) }}
        
        </li>
      </ul>
    </div>
    <button class="btn btn--primary w100" @click="addStopLocation">Add Stop</button>
    <!-- Map -->
    <l-map ref="map" :zoom="zoom" :center="center" style="pointer-events: none; height: 400px; width: 100%">
      <l-tile-layer :url="url" />
      <l-marker v-if="startLocationData" :lat-lng="startLocationCoords" />
      <l-marker v-if="endLocationData" :lat-lng="endLocationCoords" />
      <l-marker v-for="(stop, index) in stopCoords" :key="index" :lat-lng="stop"/>
      <l-polyline v-if="routeCoords.length > 0" :lat-lngs="routeCoords" color="blue" />
    </l-map>
      <button class="btn btn--primary w100" @click='validateLocations'>Submit</button>

    <p class="error-message" v-if="errorMessage">{{ errorMessage }}</p>
  </div>

      <div v-if="distance !== null && duration !== null">
        <div class="label">
          <img class="label-picto" src="/images/distance.png"/>
          <p class="label-label"><strong>Total Distance:</strong> {{ formattedDistance }} km</p>
        </div>
        <div class="label">
          <img class="label-picto" src="/images/clock.png"/>
          <p class="label-label"><strong>Total Duration:</strong> {{ formattedDuration }}</p>
        </div>
      </div>
          <!-- <label for="stops">Stops (Optional)</label>
          <div v-for="(stop, index) in ride.stops" :key="index">
          <input v-model="ride.stops[index]" type="text" placeholder="Enter stop location">
          <button @click="removeStop(index)">Remove</button>
          </div>
          <button @click="addStop">Add Stop</button> -->
  
      <!-- Stops Input -->


      <!-- Action Buttons -->
      <div v-if="distance !== null && duration !== null">
        <div class="label">
          <img class="label-picto" src="/images/tourist.png"/>
          <label for="departureTime" class="label-label">Departure Date & Time</label>
        </div>
          <div class="time-picker">
            <input
            class="custom-date-picker "
              v-model="selectedDate"
              type="date"
              @change="updateFullDateTime"
            />
            <select v-model="selectedHour" @change="updateFullDateTime">
              <option v-for="hour in hours" :key="hour" :value="hour">{{ hour }}h</option>
            </select>
            <select v-model="selectedMinute" @change="updateFullDateTime">
              <option v-for="minute in minutes" :key="minute" :value="minute">{{ minute }}m</option>
            </select>
          </div>
          <p>Selected Departure Time: {{ niceDepartureTime }}</p>
          <div class="label">
            <img class="label-picto" src="/images/return.png"/>
            <label for="returnTrip" class="label-label">Return Trip?</label>
            <input  class="label-check" id='returnTrip' v-model="ride.isReturnTrip" type="checkbox">
          </div>
          <div v-if="ride.isReturnTrip">
            <div class='time-picker'>

              <input
              color="#8ace00"
                v-model="selectedReturnDate"
                type="date"
                @change="updateFullReturnDateTime"
              />
              <select v-model="selectedReturnHour" @change="updateFullReturnDateTime">
                <option v-for="hour in hours" :key="hour" :value="hour">{{ hour }}h</option>
              </select>
              <select v-model="selectedReturnMinute" @change="updateFullReturnDateTime">
                <option v-for="minute in minutes" :key="minute" :value="minute">{{ minute }}m</option>
              </select>
            </div>
            <p>Selected Return Time: {{ niceReturnTime }}</p>
          </div>
        
        <!-- Section 2: Vehicle Information -->
        <div class="form-section">
            <h2>Vehicle Information</h2>
                  <!-- Section for adding a new car -->
            <button v-if="showAddCar" class="btn btn--primary w100" @click="showAddCar=false">
              Add A car
            </button> 
            <div v-else class="add-car-section">
              <h3>Add a New Car to Your <span>SpawnARide</span> Account</h3>

                <!-- Select Make -->
                <div class="label">
                  <label class="" for="make">Make:</label>
                </div>
                <select v-model="selectedMake" @change="onMakeChange">
                  <option value="">Select Make</option>
                  <option v-for="(models, make) in carData" :key="make" :value="make">{{ make }}</option>
                </select>

                <!-- Select Model -->
                <div class="label">
                  <label class="" for="model">Model:</label>
                </div>
                <select v-model="selectedModel" :disabled="!selectedMake">
                  <option value="">Select Model</option>
                  <option v-for="model in carData[selectedMake]" :key="model" :value="model">{{ model }}</option>
                </select>

                <!-- Select Year -->
                <div class="label">
                  <label class="" for="year">Year:</label>
                </div>
                <select v-model="selectedYear" :disabled="!selectedModel">
                  <option value="">Select Year</option>
                  <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
                </select>

                <div class="label">
                  <label class="" for="color">Color:</label>
                </div>
                <select v-model="newCar.color">
                  <option value="">Select Color</option>
                  <option v-for="(color, index) in CarColors" :key="index" :value="color">{{ color }}</option>
                </select>

              <div class="form-group">
                <div class="label">
                  <label class="" for="carPlate">Plate number:</label>
                </div>
                <input v-model="newCar.plate" type="text" placeholder="Enter car plate number" required />
              </div>

              <div class="label">
                <img class="label-picto" src="/images/car-seat-1.png"/>
                <label class="label-label" for="newCapacity">Capacity (max passengers):</label>
              </div>
              <input id='newCapacity' v-model="newCar.capacity" type="text" placeholder="Enter car max passenfers" required />
              <div class="label">
                <img class="label-picto" src="/images/tire.png"/>
                <label for="newWT" class="label-label">Winter Tires?</label>
                <input  class="label-check" id='newWT' v-model="newCar.winter_tires" type="checkbox">
              </div>
              <div class="label">
                <img class="label-picto" src="/images/air-conditioner (1).png"/>
                <label for="newAirConditioning" class="label-label">Air Conditioning?</label>
                <input  class="label-check" id='newAirConditioning' v-model="newCar.air_conditioning" type="checkbox">
              </div>
              <!-- Image upload -->
              <div class="form-group">
                <label for="carImage">Upload Car Image:</label>
                <input type="file" @change="onImageSelected" accept="image/*" />
              </div>

              <!-- Display image preview -->
              <div v-if="newCar.image">
                <h4>Image Preview:</h4>
                <div alt="Profile Picture" class="car-picture" :style="'background-image: url(' + newCar.image + ')'"></div>
              </div>

              <button class="btn btn--primary w100" type="button" @click="addCar">Add Car</button>
              <button class="btn btn w100" style="background-color: #ff9999" v-if="cars.length" type="button" @click="showAddCar=true">Cancel</button>
            </div>
            <div class="label">
              <img class="label-picto" src="/images/car-select.png"/>
              <label for="vehicle" class="label-label">Select Vehicle</label>
            </div>
            <select v-model="ride.vehicleId" @change="setCar()">
              <option v-for="car in cars" :key="car.id" :value="car.id">{{ car.year }} {{ car.make }} {{ car.model }}</option>
            </select>
            <div :style="'background-image: url(' + rideImage + ')'" v-if="rideImage" class="car-image"></div>
            <div class="label">
              <img class="label-picto" src="/images/car-seat-1.png"/>
              <label class="label-label" for="seats">Seats Available</label>
            </div>
            <input v-model="ride.seatsAvailable" type="number" min="1" max="7">
            <div class="label">
              <img class="label-picto" src="/images/bag.png"/>
              <label class="label-label" for="baggageType">Baggage Type</label>
            </div>
            <label for="baggageType">Baggage Type</label>
            <select v-model="ride.baggageType">
              <option value="small">Small </option>
              <option value="medium">Medium</option>
              <option value="large">Large</option>
            </select>
    
            <div class="label">
              <img class="label-picto" src="/images/tire.png"/>
              <label for="winterTires" class="label-label">Winter Tires?</label>
              <input  class="label-check" id='winterTires' v-model="ride.winterTires" type="checkbox">
            </div>
            <div class="label">
              <img class="label-picto" src="/images/air-conditioner (1).png"/>
              <label for="airConditioning" class="label-label">Air Conditioning?</label>
              <input  class="label-check" id='airConditioning' v-model="ride.airConditioning" type="checkbox">
            </div>
        </div>
    
        <!-- Section 3: Passenger Preferences -->
        <div class="form-section">
            <h2>Passenger Preferences</h2>
            <div class="label">
              <img class="label-picto" src="/images/drive.png"/>
              <label for="bicyclesAllowed" class="label-label">Bicycles Allowed?</label>
              <input  class="label-check" id='bicyclesAllowed' v-model="ride.bicyclesAllowed" type="checkbox">
            </div>
            <div class="label">
              <img class="label-picto" src="/images/skii.png"/>
              <label for="snowboardsAllowed" class="label-label">Snowboards/Ski Allowed?</label>
              <input  class="label-check" id='snowboardsAllowed' v-model="ride.snowboardsAllowed" type="checkbox">
            </div>
            <div class="label">
              <img class="label-picto" src="/images/paws.png"/>
              <label for="animalsAllowed" class="label-label">Animals Allowed?</label>
              <input  class="label-check" id='animalsAllowed' v-model="ride.animalsAllowed" type="checkbox">
            </div>
            <div class="label">
              <img class="label-picto" src="/images/smoking.png"/>
              <label for="smokingAllowed" class="label-label">Smoking Allowed?</label>
              <input  class="label-check" id='smokingAllowed' v-model="ride.smokingAllowed" type="checkbox">
            </div>
        </div>
    
        <!-- Section 4: Fare Information -->
        <div class="form-section">
            <h2>Fare Information</h2>
            <div class="label">
              <img class="label-picto" src="/images/fare.png"/>
              <label for="fare" class="label-label">Fare Per Seat?</label>
            </div>
              <table>
                <thead>
                  <tr>
                    <th>Offered</th>
                    <th>Fare</th>
                    <th>Seats</th>
                    <th>Details</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(fare, index) in ride.fare" :key="index">
                    <td>
                      <input class="label-check--offered" v-model="ride.fare[index].offered" type="checkbox" />
                    </td>
                    <td>
                      <input 
                        v-if="ride.fare[index].offered"
                        class="input-fare" 
                        v-model="ride.fare[index].fare" 
                        type="number" 
                        min="0" 
                        step="0.01">
                    </td>

                    <td>
                      <input 
                        v-if="ride.fare[index].offered"
                        class="input-fare" 
                        v-model="ride.fare[index].seats" 
                        type="number" 
                        min="0" 
                        step="0.01">
                    </td>
                    <td>
                      <p>{{ fare.from }} to {{ fare.to }}</p>
                      <p>Recommended: <span>$ {{ fare.suggestedFare }}</span> per seat</p>
                    </td>
                  </tr>
                </tbody>
              </table>
            <div class="label">
              <img class="label-picto" src="/images/change.png"/>
              <label for="currency" class="label-label">Currency?</label>
            </div>
            <select v-model="ride.currency">
            <option value="CAD">CAD</option>
            <option value="USD">USD</option>
            <option value="EUR">EUR</option>
            </select>
        </div>
    
        <!-- Section 5: Additional Information -->
        <div class="form-section">
            <h2>Additional Information</h2>
            <textarea v-model="ride.notes" placeholder="Add any additional information..."></textarea>
        </div>
    
        <!-- Section 6: Post Ride -->
         <p class="error-message" v-html="postRideError"></p>
        <div class="form-section">
            <button  class="btn btn--primary w100" @click="postRide">Post Ride</button>
            <button  class="btn btn--primary w100" @click="previewRide">Preview Ride</button>
        </div>
        </div>
    </div>
  </div>
  <div v-if="isLoading" class="loaging-page"><img class="loaging-page__loader" src="@/assets/images/loader.gif"/></div>
  </div>
  </template>
  
  <script>  
  import axios from 'axios';
  import { LMap, LTileLayer, LMarker, LPolyline } from "@vue-leaflet/vue-leaflet";
  import "leaflet/dist/leaflet.css";
  import Cookies from 'js-cookie';
  import { useNotification } from "@kyvg/vue3-notification";

  const { notify } = useNotification()

  export default {
    components: {
      LMap,
      LTileLayer,
      LMarker,
      LPolyline,
    },
    data() {
      return {
        postRideError: '',
        isLoading: false,
        token: Cookies.get('authToken'),
        apiUri: "https://api.spawnaride.com/",
        // apiUri: "http://localhost:3009/",
        startLocation: '',
        endLocation: '',
        suggestions: {
          start: [],
          end: [],
        },
        carPictureFile: null,
        distance: null,
        duration: null,
        startLocationData: null,
        endLocationData: null,
        startLocationCoords: null,
        endLocationCoords: null,
        routeCoords: [],
        errorMessage: '',
        zoom: 6,
        center: [45.5019535, -73.5710061], // Montreal
        url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
        filteredFromCities: [],
        selectedFromCity: '',
        niceDepartureTime: '',
        niceReturnTime: '',
        stops: [], // To store stop locations
        stopLocations: [] , // To store stop input fields
        stopCoords: [] , // To store stop coords fields
        stopLocationData: [],
        stopSuggestions: [], // To store suggestions for each stop
        "CarColors": [
          "White",
          "Black",
          "Gray",
          "Silver",
          "Red",
          "Blue",
          "Brown",
          "Green",
          "Yellow",
          "Orange",
          "Beige",
          "Gold",
          "Purple",
          "Pink",
          "Burgundy",
          "Turquoise",
          "Navy Blue",
          "Dark Green",
          "Ivory",
          "Charcoal",
          "Bronze",
          "Magenta",
          "Teal",
          "Lavender",
          "Copper",
          "Champagne",
          "Mint Green",
          "Cobalt Blue",
          "Lime Green",
          "Peach",
          "Maroon",
          "Midnight Blue",
          "Crimson",
          "Pearl White",
          "Matte Black",
          "Matte Gray",
          "Gunmetal",
          "Graphite",
          "Electric Blue",
          "Rose Gold",
          "Anthracite",
          "Sunset Orange"
        ],
        carData: {
          "Acura": ["ILX", "MDX", "NSX", "RDX", "TLX", "RLX", "ZDX"],
          "Alfa Romeo": ["Giulia", "Stelvio", "4C", "Tonale", "Mito"],
          "Aston Martin": ["DB11", "DBS", "Vantage", "Rapide", "Vanquish", "Valhalla"],
          "Audi": ["A3", "A4", "A6", "Q3", "Q5", "Q7", "Q8", "e-tron", "RS7", "TT", "R8"],
          "Bentley": ["Bentayga", "Continental", "Flying Spur", "Mulsanne"],
          "BMW": ["2 Series", "3 Series", "4 Series", "5 Series", "7 Series", "X1", "X3", "X5", "X7", "i3", "i8", "Z4", "M4", "M5"],
          "Buick": ["Encore", "Envision", "Enclave", "Regal", "LaCrosse", "Cascada", "Lucerne"],
          "Cadillac": ["Escalade", "XT4", "XT5", "XT6", "CT4", "CT5", "CT6", "ATS", "SRX"],
          "Chevrolet": ["Silverado", "Malibu", "Equinox", "Tahoe", "Traverse", "Blazer", "Camaro", "Corvette", "Trailblazer", "Suburban", "Colorado", "Impala", "Sonic", "Volt"],
          "Chrysler": ["300", "Pacifica", "Voyager", "Aspen"],
          "Dodge": ["Challenger", "Charger", "Durango", "Grand Caravan", "Journey", "Viper", "Dart"],
          "Ferrari": ["488", "Roma", "SF90", "Portofino", "F8 Tributo", "812 Superfast"],
          "Fiat": ["500", "500X", "500L", "Panda", "Tipo"],
          "Ford": ["F-150", "Escape", "Explorer", "Mustang", "Edge", "Expedition", "Bronco", "Ranger", "Maverick", "Fusion", "Fiesta", "Taurus", "EcoSport", "Transit", "Transit Connect"],
          "Genesis": ["G70", "G80", "G90", "GV70", "GV80"],
          "GMC": ["Acadia", "Canyon", "Sierra 1500", "Terrain", "Yukon", "Sierra 2500", "Sierra 3500", "Savana"],
          "Honda": ["Civic", "Accord", "CR-V", "Pilot", "Fit", "Odyssey", "Ridgeline", "HR-V", "Passport", "Insight", "Element", "S2000"],
          "Hyundai": ["Elantra", "Santa Fe", "Tucson", "Sonata", "Kona", "Palisade", "Veloster", "Venue", "Ioniq", "Genesis Coupe"],
          "Infiniti": ["Q50", "Q60", "QX50", "QX60", "QX80", "QX30"],
          "Jaguar": ["F-Pace", "E-Pace", "I-Pace", "XE", "XF", "XJ", "F-Type"],
          "Jeep": ["Cherokee", "Grand Cherokee", "Wrangler", "Compass", "Renegade", "Gladiator", "Patriot"],
          "Kia": ["Forte", "Sorento", "Sportage", "Soul", "Telluride", "Seltos", "Carnival", "K5", "Rio", "Optima", "Stinger"],
          "Lamborghini": ["Huracan", "Aventador", "Urus"],
          "Land Rover": ["Range Rover", "Discovery", "Defender", "Evoque", "Freelander"],
          "Lexus": ["ES", "RX", "NX", "GX", "LS", "LX", "IS", "LC", "RC"],
          "Lincoln": ["Aviator", "Corsair", "Nautilus", "Navigator", "MKC", "MKZ", "Continental", "MKT"],
          "Maserati": ["Ghibli", "Levante", "Quattroporte", "GranTurismo"],
          "Mazda": ["CX-3", "CX-5", "CX-9", "Mazda3", "Mazda6", "MX-5 Miata", "CX-30", "RX-8"],
          "McLaren": ["GT", "720S", "600LT", "570S", "650S", "P1", "Senna"],
          "Mercedes-Benz": ["A-Class", "C-Class", "E-Class", "S-Class", "GLA", "GLC", "GLE", "GLS", "G-Class", "CLA", "SL", "AMG GT", "EQC"],
          "Mini": ["Cooper", "Clubman", "Countryman", "Paceman"],
          "Mitsubishi": ["Eclipse Cross", "Outlander", "Outlander Sport", "Mirage", "Mirage G4", "Lancer", "Montero"],
          "Nissan": ["Altima", "Frontier", "Leaf", "Maxima", "Murano", "Pathfinder", "Rogue", "Sentra", "Titan", "Versa", "Armada", "370Z", "GT-R"],
          "Porsche": ["Cayenne", "Macan", "Panamera", "911", "718", "Taycan", "Boxster"],
          "Ram": ["1500", "2500", "3500", "ProMaster City", "ProMaster"],
          "Rolls-Royce": ["Phantom", "Ghost", "Wraith", "Cullinan", "Dawn"],
          "Subaru": ["Ascent", "Crosstrek", "Forester", "Impreza", "Legacy", "Outback", "WRX", "BRZ"],
          "Tesla": ["Model 3", "Model S", "Model X", "Model Y", "Cybertruck", "Roadster"],
          "Toyota": ["Corolla", "Camry", "RAV4", "Highlander", "Tacoma", "Tundra", "4Runner", "Prius", "Avalon", "Sienna", "Supra", "C-HR"],
          "Volkswagen": ["Atlas", "Golf", "Jetta", "Passat", "Tiguan", "ID.4", "Beetle", "Arteon", "Touareg"],
          "Volvo": ["S60", "S90", "XC40", "XC60", "XC90", "V60", "V90", "C40 Recharge"],
        },

        cities: [
            // Quebec Cities
            {
                city_code: 'MTL',
                province_code: 'QC',
                fr: { city: 'Montréal', province: 'Québec' },
                en: { city: 'Montreal', province: 'Quebec' }
            },
            {
                city_code: 'LAV',
                province_code: 'QC',
                fr: { city: 'Laval', province: 'Québec' },
                en: { city: 'Laval', province: 'Quebec' }
            },
            {
                city_code: 'QUE',
                province_code: 'QC',
                fr: { city: 'Québec', province: 'Québec' },
                en: { city: 'Quebec City', province: 'Quebec' }
            },
            {
                city_code: 'GAT',
                province_code: 'QC',
                fr: { city: 'Gatineau', province: 'Québec' },
                en: { city: 'Gatineau', province: 'Quebec' }
            },
            {
                city_code: 'LON',
                province_code: 'QC',
                fr: { city: 'Longueuil', province: 'Québec' },
                en: { city: 'Longueuil', province: 'Quebec' }
            },
            {
                city_code: 'SHE',
                province_code: 'QC',
                fr: { city: 'Sherbrooke', province: 'Québec' },
                en: { city: 'Sherbrooke', province: 'Quebec' }
            },
            {
                city_code: 'TR',
                province_code: 'QC',
                fr: { city: 'Trois-Rivières', province: 'Québec' },
                en: { city: 'Trois-Rivières', province: 'Quebec' }
            },
            {
                city_code: 'SAG',
                province_code: 'QC',
                fr: { city: 'Saguenay', province: 'Québec' },
                en: { city: 'Saguenay', province: 'Quebec' }
            },

            // Ontario Cities
            {
                city_code: 'TOR',
                province_code: 'ON',
                fr: { city: 'Toronto', province: 'Ontario' },
                en: { city: 'Toronto', province: 'Ontario' }
            },
            {
                city_code: 'OTT',
                province_code: 'ON',
                fr: { city: 'Ottawa', province: 'Ontario' },
                en: { city: 'Ottawa', province: 'Ontario' }
            },
            {
                city_code: 'MIS',
                province_code: 'ON',
                fr: { city: 'Mississauga', province: 'Ontario' },
                en: { city: 'Mississauga', province: 'Ontario' }
            },
            {
                city_code: 'BRA',
                province_code: 'ON',
                fr: { city: 'Brampton', province: 'Ontario' },
                en: { city: 'Brampton', province: 'Ontario' }
            },
            {
                city_code: 'LON',
                province_code: 'ON',
                fr: { city: 'London', province: 'Ontario' },
                en: { city: 'London', province: 'Ontario' }
            },
            {
                city_code: 'KIC',
                province_code: 'ON',
                fr: { city: 'Kitchener', province: 'Ontario' },
                en: { city: 'Kitchener', province: 'Ontario' }
            },
            {
                city_code: 'WIN',
                province_code: 'ON',
                fr: { city: 'Windsor', province: 'Ontario' },
                en: { city: 'Windsor', province: 'Ontario' }
            },
            {
                city_code: 'NIAG',
                province_code: 'ON',
                fr: { city: 'Niagara Falls', province: 'Ontario' },
                en: { city: 'Niagara Falls', province: 'Ontario' }
            },
            {
            "city_code": "VAN",
            "province_code": "BC",
            "fr": { "city": "Vancouver", "province": "Colombie-Britannique" },
            "en": { "city": "Vancouver", "province": "British Columbia" }
            },
            {
            "city_code": "NOU",
            "province_code": "BC",
            "fr": { "city": "North Vancouver", "province": "Colombie-Britannique" },
            "en": { "city": "North Vancouver", "province": "British Columbia" }
            },
            {
            "city_code": "SOU",
            "province_code": "BC",
            "fr": { "city": "South Vancouver", "province": "Colombie-Britannique" },
            "en": { "city": "South Vancouver", "province": "British Columbia" }
            },
            {
            "city_code": "WES",
            "province_code": "BC",
            "fr": { "city": "West Vancouver", "province": "Colombie-Britannique" },
            "en": { "city": "West Vancouver", "province": "British Columbia" }
            },
            {
            "city_code": "RIC",
            "province_code": "BC",
            "fr": { "city": "Richmond", "province": "Colombie-Britannique" },
            "en": { "city": "Richmond", "province": "British Columbia" }
            },
            {
            "city_code": "BUR",
            "province_code": "BC",
            "fr": { "city": "Burnaby", "province": "Colombie-Britannique" },
            "en": { "city": "Burnaby", "province": "British Columbia" }
            },
            {
            "city_code": "DEL",
            "province_code": "BC",
            "fr": { "city": "Delta", "province": "Colombie-Britannique" },
            "en": { "city": "Delta", "province": "British Columbia" }
            },
            {
            "city_code": "SUR",
            "province_code": "BC",
            "fr": { "city": "Surrey", "province": "Colombie-Britannique" },
            "en": { "city": "Surrey", "province": "British Columbia" }
            },
            {
            "city_code": "LAD",
            "province_code": "BC",
            "fr": { "city": "Ladner", "province": "Colombie-Britannique" },
            "en": { "city": "Ladner", "province": "British Columbia" }
            },
            {
            "city_code": "POR",
            "province_code": "BC",
            "fr": { "city": "Port Coquitlam", "province": "Colombie-Britannique" },
            "en": { "city": "Port Coquitlam", "province": "British Columbia" }
            },
            {
            "city_code": "COQ",
            "province_code": "BC",
            "fr": { "city": "Coquitlam", "province": "Colombie-Britannique" },
            "en": { "city": "Coquitlam", "province": "British Columbia" }
            },
            {
            "city_code": "MAP",
            "province_code": "BC",
            "fr": { "city": "Maple Ridge", "province": "Colombie-Britannique" },
            "en": { "city": "Maple Ridge", "province": "British Columbia" }
            },
            {
            "city_code": "LANG",
            "province_code": "BC",
            "fr": { "city": "Langley", "province": "Colombie-Britannique" },
            "en": { "city": "Langley", "province": "British Columbia" }
            },
            {
            "city_code": "PITT",
            "province_code": "BC",
            "fr": { "city": "Pitt Meadows", "province": "Colombie-Britannique" },
            "en": { "city": "Pitt Meadows", "province": "British Columbia" }
            },
            {
            "city_code": "ABO",
            "province_code": "BC",
            "fr": { "city": "Abbotsford", "province": "Colombie-Britannique" },
            "en": { "city": "Abbotsford", "province": "British Columbia" }
            },
            {
            "city_code": "CAL",
            "province_code": "AB",
            "fr": { "city": "Calgary", "province": "Alberta" },
            "en": { "city": "Calgary", "province": "Alberta" }
            },
            {
            "city_code": "EDM",
            "province_code": "AB",
            "fr": { "city": "Edmonton", "province": "Alberta" },
            "en": { "city": "Edmonton", "province": "Alberta" }
            },
            {
            "city_code": "RED",
            "province_code": "AB",
            "fr": { "city": "Red Deer", "province": "Alberta" },
            "en": { "city": "Red Deer", "province": "Alberta" }
            },
            {
            "city_code": "LAC",
            "province_code": "AB",
            "fr": { "city": "Lacombe", "province": "Alberta" },
            "en": { "city": "Lacombe", "province": "Alberta" }
            },
            {
            "city_code": "LETH",
            "province_code": "AB",
            "fr": { "city": "Lethbridge", "province": "Alberta" },
            "en": { "city": "Lethbridge", "province": "Alberta" }
            },
            {
            "city_code": "AIR",
            "province_code": "AB",
            "fr": { "city": "Airdrie", "province": "Alberta" },
            "en": { "city": "Airdrie", "province": "Alberta" }
            },
            {
            "city_code": "MED",
            "province_code": "AB",
            "fr": { "city": "Medicine Hat", "province": "Alberta" },
            "en": { "city": "Medicine Hat", "province": "Alberta" }
            },
            {
            "city_code": "COLE",
            "province_code": "AB",
            "fr": { "city": "Cold Lake", "province": "Alberta" },
            "en": { "city": "Cold Lake", "province": "Alberta" }
            },
            {
            "city_code": "GRAN",
            "province_code": "AB",
            "fr": { "city": "Grand Prairie", "province": "Alberta" },
            "en": { "city": "Grande Prairie", "province": "Alberta" }
            },
            {
            "city_code": "BEA",
            "province_code": "AB",
            "fr": { "city": "Fort McMurray", "province": "Alberta" },
            "en": { "city": "Fort McMurray", "province": "Alberta" }
            },
            {
            "city_code": "STAL",
            "province_code": "AB",
            "fr": { "city": "St. Albert", "province": "Alberta" },
            "en": { "city": "St. Albert", "province": "Alberta" }
            },
            {
            "city_code": "OKOT",
            "province_code": "AB",
            "fr": { "city": "Okotoks", "province": "Alberta" },
            "en": { "city": "Okotoks", "province": "Alberta" }
            },
            {
            "city_code": "COCHR",
            "province_code": "AB",
            "fr": { "city": "Cochrane", "province": "Alberta" },
            "en": { "city": "Cochrane", "province": "Alberta" }
            },
            {
            "city_code": "DRUM",
            "province_code": "AB",
            "fr": { "city": "Drumheller", "province": "Alberta" },
            "en": { "city": "Drumheller", "province": "Alberta" }
            },
            {
            "city_code": "BRO",
            "province_code": "AB",
            "fr": { "city": "Brooks", "province": "Alberta" },
            "en": { "city": "Brooks", "province": "Alberta" }
            },
            {
            "city_code": "REG",
            "province_code": "SK",
            "fr": { "city": "Regina", "province": "Saskatchewan" },
            "en": { "city": "Regina", "province": "Saskatchewan" }
            },
            {
            "city_code": "SASK",
            "province_code": "SK",
            "fr": { "city": "Saskatoon", "province": "Saskatchewan" },
            "en": { "city": "Saskatoon", "province": "Saskatchewan" }
            },
            {
            "city_code": "PRIN",
            "province_code": "SK",
            "fr": { "city": "Prince Albert", "province": "Saskatchewan" },
            "en": { "city": "Prince Albert", "province": "Saskatchewan" }
            },
            {
            "city_code": "MOOSE",
            "province_code": "SK",
            "fr": { "city": "Moose Jaw", "province": "Saskatchewan" },
            "en": { "city": "Moose Jaw", "province": "Saskatchewan" }
            },
            {
            "city_code": "SWIFT",
            "province_code": "SK",
            "fr": { "city": "Swift Current", "province": "Saskatchewan" },
            "en": { "city": "Swift Current", "province": "Saskatchewan" }
            },
            {
            "city_code": "YORK",
            "province_code": "SK",
            "fr": { "city": "Yorkton", "province": "Saskatchewan" },
            "en": { "city": "Yorkton", "province": "Saskatchewan" }
            },
            {
            "city_code": "ESTEV",
            "province_code": "SK",
            "fr": { "city": "Estevan", "province": "Saskatchewan" },
            "en": { "city": "Estevan", "province": "Saskatchewan" }
            },
            {
            "city_code": "NIP",
            "province_code": "SK",
            "fr": { "city": "Nipawin", "province": "Saskatchewan" },
            "en": { "city": "Nipawin", "province": "Saskatchewan" }
            },
            {
            "city_code": "HUMB",
            "province_code": "SK",
            "fr": { "city": "Humboldt", "province": "Saskatchewan" },
            "en": { "city": "Humboldt", "province": "Saskatchewan" }
            },
            {
            "city_code": "WAT",
            "province_code": "SK",
            "fr": { "city": "Watrous", "province": "Saskatchewan" },
            "en": { "city": "Watrous", "province": "Saskatchewan" }
            },
            {
            "city_code": "SHELL",
            "province_code": "SK",
            "fr": { "city": "Shellbrook", "province": "Saskatchewan" },
            "en": { "city": "Shellbrook", "province": "Saskatchewan" }
            },
            {
            "city_code": "LLOY",
            "province_code": "SK",
            "fr": { "city": "Lloydminster", "province": "Saskatchewan" },
            "en": { "city": "Lloydminster", "province": "Saskatchewan" }
            },
            {
            "city_code": "BATTLE",
            "province_code": "SK",
            "fr": { "city": "Battleford", "province": "Saskatchewan" },
            "en": { "city": "Battleford", "province": "Saskatchewan" }
            },
            {
            "city_code": "MART",
            "province_code": "SK",
            "fr": { "city": "Martensville", "province": "Saskatchewan" },
            "en": { "city": "Martensville", "province": "Saskatchewan" }
            },
            {
            "city_code": "WPG",
            "province_code": "MB",
            "fr": { "city": "Winnipeg", "province": "Manitoba" },
            "en": { "city": "Winnipeg", "province": "Manitoba" }
            },
            {
            "city_code": "BRAND",
            "province_code": "MB",
            "fr": { "city": "Brandon", "province": "Manitoba" },
            "en": { "city": "Brandon", "province": "Manitoba" }
            },
            {
            "city_code": "STE",
            "province_code": "MB",
            "fr": { "city": "Steinbach", "province": "Manitoba" },
            "en": { "city": "Steinbach", "province": "Manitoba" }
            },
            {
            "city_code": "THOMP",
            "province_code": "MB",
            "fr": { "city": "Thompson", "province": "Manitoba" },
            "en": { "city": "Thompson", "province": "Manitoba" }
            },
            {
            "city_code": "DAUPH",
            "province_code": "MB",
            "fr": { "city": "Dauphin", "province": "Manitoba" },
            "en": { "city": "Dauphin", "province": "Manitoba" }
            },
            {
            "city_code": "FLIN",
            "province_code": "MB",
            "fr": { "city": "Flin Flon", "province": "Manitoba" },
            "en": { "city": "Flin Flon", "province": "Manitoba" }
            },
            {
            "city_code": "MORD",
            "province_code": "MB",
            "fr": { "city": "Morden", "province": "Manitoba" },
            "en": { "city": "Morden", "province": "Manitoba" }
            },
            {
            "city_code": "PORT",
            "province_code": "MB",
            "fr": { "city": "Portage la Prairie", "province": "Manitoba" },
            "en": { "city": "Portage la Prairie", "province": "Manitoba" }
            },
            {
            "city_code": "SELK",
            "province_code": "MB",
            "fr": { "city": "Selkirk", "province": "Manitoba" },
            "en": { "city": "Selkirk", "province": "Manitoba" }
            },
            {
            "city_code": "GIMLI",
            "province_code": "MB",
            "fr": { "city": "Gimli", "province": "Manitoba" },
            "en": { "city": "Gimli", "province": "Manitoba" }
            },
            {
            "city_code": "NEEP",
            "province_code": "MB",
            "fr": { "city": "Neepawa", "province": "Manitoba" },
            "en": { "city": "Neepawa", "province": "Manitoba" }
            },
            {
            "city_code": "WINNI",
            "province_code": "MB",
            "fr": { "city": "Winnipeg Beach", "province": "Manitoba" },
            "en": { "city": "Winnipeg Beach", "province": "Manitoba" }
            },
            {
            "city_code": "BIRT",
            "province_code": "MB",
            "fr": { "city": "Birtle", "province": "Manitoba" },
            "en": { "city": "Birtle", "province": "Manitoba" }
            },
            {
            "city_code": "CARB",
            "province_code": "MB",
            "fr": { "city": "Carberry", "province": "Manitoba" },
            "en": { "city": "Carberry", "province": "Manitoba" }
            }
  
        ],
        selectedMake: "",
        selectedModel: "",
        selectedYear: "",

        years: this.generateYears(1990, new Date().getFullYear() + 2), // Array of years from 1990 to current year
        selectedDate: '', // Stores the selected date
        selectedHour: '00', // Default hour
        selectedMinute: '00', // Default minute
        selectedReturnDate: '', // Stores the selected date
        selectedReturnHour: '00', // Default hour
        selectedReturnMinute: '00', // Default minute
        rideImage: null,
        ride: {
          startLocation: '',
          endLocation: '',
          stops: [],
          departureTime: '',
          isReturnTrip: false,
          returnTime: '',
          vehicleId: null,
          seatsAvailable: 3,
          baggageType: 'medium',
          winterTires: false,
          airConditioning: false,
          bicyclesAllowed: false,
          snowboardsAllowed: false,
          animalsAllowed: false,
          smokingAllowed: false,
          fare: [],
          currency: 'CAD',
          notes: ''
          },
          newCar: {
            brand: '',
            capacity: 3,
            winter_tires: 0,
            air_conditioning: 0,
            model: '',
            year: '',
            color: '',
            plate: '',
            image:  '/images/icon-3286845_640.png',
          },
        addedCars: [],
        showAddCar: false,
        cars: [
          // { id: 1, year: 2024, make: 'Toyota', model: 'Prius' },
          // { id: 2, year: 2022, make: 'Honda', model: 'Accord' },
          // Add more cars as needed
        ]
      };
    },
    computed: {
      hours() {
        // Generate hours from 0 to 23
        return Array.from({ length: 24 }, (_, i) => (i < 10 ? `0${i}` : i));
      },
      minutes() {
        // Options for minutes: 00, 15, 30, 45
        return ['00', '15', '30', '45'];
      },
      formattedDistance() {
        return this.distance ? this.distance.toFixed(2) : null; // Round to 2 decimals
      },
      formattedDuration() {
        if (this.duration === null) return null;
        const hours = Math.floor(this.duration / 60);
        const minutes = Math.round(this.duration % 60);
        return `${hours} hour${hours !== 1 ? 's' : ''} ${minutes} minute${minutes !== 1 ? 's' : ''}`.trim();
      },
    },
    methods: {
      isValidDate (dateString) {
        const date = new Date(dateString);
        return !isNaN(date.getTime()); // Check if date is valid
      },
      async addCar() {
        this.isLoading = true;
        const formData = new FormData();
        formData.append('make', this.selectedMake);
        formData.append('model', this.selectedModel);
        formData.append('year', this.selectedYear);
        formData.append('color', this.newCar.color);
        formData.append('plate', this.newCar.plate);
        formData.append('capacity', this.newCar.capacity);
        formData.append('winter_tires', this.newCar.winter_tires);
        formData.append('air_conditioning', this.newCar.air_conditioning);
        if (this.carPictureFile) {
            formData.append('carPictureFile', this.carPictureFile);
          }
        // Append image file if selected
        if (this.newCar.imageFile) {
          formData.append('image', this.newCar.imageFile);
        }

        try {
          const response = await axios.post( this.apiUri + 'api/cars', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              "Authorization": `Bearer ${this.token}`, // Include your token
            },
          });
          // Optionally reset form or give feedback
          notify({
            title: "New car",
            text: "Car added successfully!",
          });
          this.fetchCars(response.data.insertId);
          this.isLoading = false;        
        } catch (error) {
          notify({
            title: "Error adding car",
            text: "Please fill all fields.",
            type: 'warn',
          });
          console.error('Error adding car:', error);
          this.isLoading = false;
        }
      },
      getNiceSuggestion(s) {
        let house_number = s?.address?.house_number ;
        let street = s?.address?.road ;
        let city = s?.address?.city || s?.address?.village || s?.address?.region || s?.address?.town || s?.address?.municipality;
        let postcode = s?.address?.postcode;
        let state = s.address?.state;
        let country = s.address?.country;
        
        if(house_number) {
            return house_number + ' ' + street + ', ' + city + ', ' + postcode + ', ' + state + ', ' + country;
        } else if(s.addresstype==='state') {
            return state + ', ' + country;
        } else {
            return city + ', ' + state + ', ' + country;
        }

      },
      selectLocation(type, suggestion, index) {
        this.stopCoords[index] = [suggestion.lat, suggestion.lon]; // Set the stop location to the selected suggestion
        this.stopLocationData[index] = suggestion;
        this.stopLocations[index] = this.getNiceSuggestion(suggestion); // Set the stop location to the selected suggestion
        this.stopSuggestions[index] = []; // Clear suggestions after selection
      },
      async fetchCars(car_id = null) {
        try {
          const response = await axios.get(this.apiUri + 'api/cars/list', {
            headers: {
              "Authorization": `Bearer ${this.token}`,
            },
          });
          this.cars = response?.data;
          if(this.cars.length) {
            const carId = car_id ? car_id : this.cars[0].id;
            this.showAddCar = true;
            this.ride.vehicleId = carId;
            const carInfo = this.cars.find(car => car.id === carId);
            this.ride.seatsAvailable = carInfo.capacity;
            this.ride.airConditioning = carInfo.air_conditioning === 1 ? true : false;
            this.ride.winterTires = carInfo.winter_tires === 1 ? true : false;
            this.rideImage = carInfo.imageUrl ? 'https://spawnaride.com/cars-pictures/' + carInfo.user_id + '/' + carInfo.imageUrl: null;
          }
          // this.user.profilePicture = response?.data?.user?.url ? 'https://spawnaride.com/users-pictures/' + response.data.user.url : null;
        } catch (error) {
          console.error('Error fetching profile:', error);
        }
      },
      setCar() {
        const carId = this.ride.vehicleId;
        const carInfo = this.cars.find(car => car.id === carId);
        this.ride.seatsAvailable = carInfo.capacity;
        this.ride.airConditioning = carInfo.air_conditioning === 1 ? true : false;
        this.ride.winterTires = carInfo.winter_tires === 1 ? true : false;
        this.rideImage = carInfo.imageUrl ? 'https://spawnaride.com/cars-pictures/' + carInfo.user_id + '/' + carInfo.imageUrl: null;
      },
      async fetchRoute() {
        if (this.startLocationCoords && this.endLocationCoords) {
          // Create waypoints string (start, stops, end)
          const waypoints = [this.startLocationCoords, ...this.stopCoords, this.endLocationCoords]
            .map(coord => `${coord[1]},${coord[0]}`)
            .join(';');
          
          const response = await axios.get(
            `https://router.project-osrm.org/route/v1/driving/${waypoints}?overview=full&geometries=geojson`
          );

          const route = response.data.routes[0];
          this.routeCoordinates = route.geometry.coordinates.map(coord => [coord[1], coord[0]]);
          // Re-center the map on the start location
          this.center = this.startLocationCoords;
        } else {
          alert('Please select valid start and end locations.');
        }
      },
      addStopLocation() {
        this.stopLocations.push(""); // Add a new empty stop location
        this.stopSuggestions.push([]); // Initialize an empty array for the new stop's suggestions
        this.duration = null;
        this.distance = null;
      },
      removeStopLocation(index) {
        this.stopLocations.splice(index, 1); // Remove the stop location at the given index
        this.stopCoords.splice(index, 1); // Remove the stop location at the given index
        this.stopSuggestions.splice(index, 1); // Optional: remove associated suggestions
        this.duration = null;
        this.distance = null;
      },
      onMakeChange() {
        // Reset model when the make is changed
        this.selectedModel = "";
      },
      generateYears(startYear, endYear) {
        const years = [];
        for (let year = startYear; year <= endYear; year++) {
          years.push(year);
        }
        return years;
      },
      onImageSelected(event) {
        const file = event.target.files[0];
        if (file) {
          this.carPictureFile = file;
          this.newCar.image = URL.createObjectURL(file); // Show the preview
        }
      },
      resetCarForm() {
        this.newCar = {
          brand: '',
          model: '',
          year: '',
          color: '',
          plate: '',
          image: null,
        };
      },
      async postRide() {
        this.isLoading = true;
        
        try {
          // Construct ride object to send
          const departureTime = this.selectedDate + 'T' + this.selectedHour + ':' + this.selectedMinute + ':00';
          const returnTime = this.selectedReturnDate + 'T' + this.selectedReturnHour + ':' + this.selectedReturnMinute + ':00';
          this.postRideError = '';
          if(!this.isValidDate(departureTime)) {
            console.log(departureTime);
            console.log('invlide date');
            this.postRideError += "Invalid Departure Date & Time.<br>"
          }
          if(!this.isValidDate(returnTime) && this.ride.isReturnTrip) {
            console.log('invlide return date');
            this.postRideError += "Invalid Departure Date & Time.<br>"
          }

          if (this.postRideError) {
            this.isLoading = false;
            return;
          } 
          const rideData = {
            startLocation: this.startLocationData,
            endLocation: this.endLocationData,
            departureTime,
            isReturnTrip: this.ride.isReturnTrip,
            returnTime,
            vehicleId: this.ride.vehicleId,
            seatsAvailable: this.ride.seatsAvailable,
            baggageType: this.ride.baggageType,
            winterTires: this.ride.winterTires,
            airConditioning: this.ride.airConditioning,
            bicyclesAllowed: this.ride.bicyclesAllowed,
            snowboardsAllowed: this.ride.snowboardsAllowed,
            animalsAllowed: this.ride.animalsAllowed,
            smokingAllowed: this.ride.smokingAllowed,
            fare: this.ride.fare,
            currency: this.ride.currency,
            notes: this.ride.notes,
            stops: this.stopLocationData
          };
          console.log(rideData);
          // Add stops to the ride data if any
          // if (this.stopCoords.length > 0) {
          //   rideData.stops = this.stopCoords.map((coord, index) => ({
          //     location: this.stopLocations[index],
          //     latitude: coord[0],
          //     longitude: coord[1],
          //   }));
          // }

          // Send the ride data to the backend
          const response = await axios.post(`${this.apiUri}api/rides`, rideData, {
            headers: {
              Authorization: `Bearer ${this.token}`,
            },
          });
          console.log('response');
          console.log(response);
          // Notify user of successful submission
          this.isLoading = false;
          notify({
            title: "Ride Posted",
            text: "Your ride has been successfully posted!",
          });
          // Optional: Reset form or navigate as needed
          this.resetRideForm();
          
        } catch (error) {
          this.isLoading = false;
          notify({
            title: "Error Posting Ride",
            text: "An error occurred while posting the ride. Please try again.",
            type: 'error',
          });
          console.error('Error posting ride:', error);
        }
      },
      resetRideForm() {
        // Reset ride form fields to their initial values
        this.ride = {
          startLocation: '',
          endLocation: '',
          stops: [],
          departureTime: '',
          isReturnTrip: false,
          returnTime: '',
          vehicleId: null,
          seatsAvailable: 3,
          baggageType: 'medium',
          winterTires: false,
          airConditioning: false,
          bicyclesAllowed: false,
          snowboardsAllowed: false,
          animalsAllowed: false,
          smokingAllowed: false,
          fare: [],
          currency: 'CAD',
          notes: '',
        };
        this.rideImage = null;
        this.stopCoords = [];
        this.stopLocations = [];
      },
      updateFullDateTime() {
        const options = {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: false,
        };
        if (this.selectedDate && this.selectedHour && this.selectedMinute) {
          // Combine date, hour, and minute into a single Date object
          const dateTime = new Date(`${this.selectedDate}T${this.selectedHour}:${this.selectedMinute}`);
          this.ride.departureTime = dateTime.toISOString().slice(0, 16); // Format: YYYY-MM-DDTHH:MM
          this.niceDepartureTime = new Date(dateTime).toLocaleString('en-US', options);

        }
      },
      updateFullReturnDateTime() {
        const options = {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          hour12: false,
        };
        if (this.selectedReturnDate && this.selectedReturnHour && this.selectedReturnMinute) {
          // Combine date, hour, and minute into a single Date object
          const dateTime = new Date(`${this.selectedReturnDate}T${this.selectedReturnHour}:${this.selectedReturnMinute}`);
          this.ride.returnTime = dateTime.toISOString().slice(0, 16); // Format: YYYY-MM-DDTHH:MM
          this.niceReturnTime = new Date(dateTime).toLocaleString('en-US', options);

        }
      },
      roundToNearest15Minutes(event) {
        const inputTime = new Date(event.target.value);
        // Get the current minutes
        const minutes = inputTime.getMinutes();
        // Round to the nearest 15 minutes
        const roundedMinutes = Math.round(minutes / 15) * 15;
        // Set the new minutes
        inputTime.setMinutes(roundedMinutes);
        // Update the model with the rounded time in ISO format
        this.ride.departureTime = inputTime.toISOString().slice(0, 16); // Format: YYYY-MM-DDTHH:MM


      },
      async getSuggestions(type, index = null) {
        this.duration = null;
        this.distance = null;
        const location = type === 'start' ? this.startLocation
                        : type === 'end' ? this.endLocation
                        : this.stopLocations[index];
          let response;
          response = await axios.get(
            this.apiUri + `api/openstreetmap/search?q=${location}`
          );

          if(response.data) {
            if (type !== 'stop') {
              this.suggestions[type] = JSON.parse(response.data);
            } {
              this.stopSuggestions[index] = JSON.parse(response.data);
            }
          }
          else {
            response = await axios.get(
              `https://nominatim.openstreetmap.org/search?format=json&q=${location}&countrycodes=CA,US&addressdetails=1&limit=5`
            );
            await axios.post( this.apiUri + 'api/openstreetmap/add', {
              q: location,
              response: response.data, // Adjust this according to your authentication system
            });

            if (type !== 'stop') {
              this.suggestions[type] = response.data;
            } {
              this.stopSuggestions[index] = response.data;
            }

          }
    },
    selectSuggestion(type, suggestion) {
      if (type === 'start') {
        this.startLocation = suggestion.display_name;
        this.startLocationData = suggestion;
        this.startLocationCoords = [suggestion.lat, suggestion.lon];
      } else {
        this.endLocation = suggestion.display_name;
        this.endLocationData = suggestion;
        this.endLocationCoords = [suggestion.lat, suggestion.lon];
      }
      // Clear suggestions after selection
      this.suggestions[type] = [];
    },
    async validateLocations() {
      this.isLoading = true;
      if (this.startLocationData && this.endLocationData) {
        const avgLat = (parseFloat(this.startLocationCoords[0]) + parseFloat(this.endLocationCoords[0])) / 2;
        const avgLon = (parseFloat(this.startLocationCoords[1]) + parseFloat(this.endLocationCoords[1])) / 2;
        this.center = [avgLat, avgLon];

        // Fetch route and draw on map
        await this.getRoute();
        this.isLoading = false;
        this.errorMessage = '';
      } else {
        this.errorMessage = 'Please select valid locations from suggestions';
        this.isLoading = false;
      }
    },
    async getRoute() {
      let osrmUrl = '';
      this.ride.fare = [];
      if (this.stopCoords.length === 0) {
        osrmUrl = `https://router.project-osrm.org/route/v1/driving/${this.startLocationCoords[1]},${this.startLocationCoords[0]};${this.endLocationCoords[1]},${this.endLocationCoords[0]}?overview=full&geometries=geojson`;
   
      }
      else {
        osrmUrl = `https://router.project-osrm.org/route/v1/driving/${this.startLocationCoords[1]},${this.startLocationCoords[0]};` + 
          this.stopCoords.map(coord => `${coord[1]},${coord[0]}`).join(';') + 
          `;${this.endLocationCoords[1]},${this.endLocationCoords[0]}?overview=full&geometries=geojson`;
        }
        try {
        const response = await axios.get(osrmUrl);
        const route = response.data.routes[0];
        this.distance=route.distance / 1000;
        this.duration=route.duration / 60;


        if (this.stopCoords.length === 0) {
            this.ride.fare.push({
              fare:Math.floor(this.distance*0.13054830287),
              from: this.startLocationData.address.city,
              to: this.endLocationData.address.city,
              suggest: Math.floor(this.distance*0.13054830287),
              distance: this.distance,
              offered: true,
              seats: this.ride.seatsAvailable
            });
          }
        else {
          const totalStops = this.stopCoords.length + 1;
          console.log(totalStops);

          // let places = [];

          for (let i=0;i<totalStops;i++) {
            let starLocation = i === 0 ? this.startLocationData : this.stopLocationData[i-1];
            for (let j=i;j<totalStops;j++) {

              let endLocation = j === (this.stopLocationData.length) ? this.endLocationData : this.stopLocationData[j];              
              osrmUrl = `https://router.project-osrm.org/route/v1/driving/${starLocation.lon},${starLocation.lat};${endLocation.lon},${endLocation.lat}?overview=full&geometries=geojson`;

              let myResponse = await axios.get(osrmUrl);
              let myRoute = myResponse.data.routes[0];
              let myDistance=myRoute.distance / 1000;
              
              this.ride.fare.push({
                fare:Math.floor(myDistance*0.13054830287),
                suggestedFare: Math.floor(myDistance*0.13054830287),
                from: starLocation.address.city,
                to: endLocation.address.city,
                distance: myDistance,
                offered: true,
                seats: this.ride.seatsAvailable
              });
            }
          }
          osrmUrl = `https://router.project-osrm.org/route/v1/driving/${this.startLocationCoords[1]},${this.startLocationCoords[0]};` + 
          this.stopCoords.map(coord => `${coord[1]},${coord[0]}`).join(';') + 
          `;${this.endLocationCoords[1]},${this.endLocationCoords[0]}?overview=full&geometries=geojson`;

          let response = await axios.get(osrmUrl);
          let route = response.data.routes[0];
          let distance=route.distance / 1000;
          console.log(distance);
        }

        this.routeCoords = route.geometry.coordinates.map(coord => [coord[1], coord[0]]); // Swap [lon, lat] to [lat, lon]
      } catch (error) {
        console.error("Error fetching route:", error);
        this.errorMessage = 'Unable to fetch the route.';
      }
    },
      filterFromCities() {
        this. selectedFromCity = '';
        if (this.ride.startLocation) {
          this.filteredFromCities = this.cities.filter(city =>
            city.en.city.toLowerCase().includes(this.ride.startLocation.toLowerCase()) ||
            city.fr.city.toLowerCase().includes(this.ride.startLocation.toLowerCase())
          );
        } else {
          this.filteredFromCities = this.cities; // Reset to all cities if input is empty
        }
      },
      selectFromCity(city) {
        this.ride.startLocation = city.en.city + ', ' + city.en.province; // Set the input field to the selected city
        this.selectedFromCity = city.city_code; // Store the selected city code
        this.filteredFromCities = []; // Clear suggestions
      },
      addStop() {
        this.ride.stops.push('');
      },
      removeStop(index) {
        this.ride.stops.splice(index, 1);
      },
      submitRide() {
        // Logic to submit the ride data to the backend
        console.log('Ride Posted:', this.ride);
      },
      previewRide() {
        // Logic to preview the ride information
        console.log('Ride Preview:', this.ride);
      }
    },
    watch: {
      // Watch for changes in the $route object
      $route() {
        // Push a new query parameter when the route changes
        this.$router.push({ query: { menuType: 'app' } });
      },
    },
    mounted () {
      this.fetchCars();
      this.$router.push({ query: { menuType: 'app' } });
    }
  };
  </script>
  
  <style scoped>
    .error-message {
      color: red;
      font-size: 16px;
    }
    .w100 {
      width: 100%;
    }
    td {
        padding: 10px;
    }
    .loaging-page {
      background-color: rgba(0,0,0,.7);
      position: fixed;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 10000;
    }
    .loaging-page__loader {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 100px;
    }
   .input-fare { 
    width: 62px;
    padding: 10px !important;
    margin: 0;
   }
   .car-picture, .car-image {
    background-color: #111;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    width: 150px;
    height: 150px;
    border-radius: 150px;
    margin-bottom: 20px;
  }
  .container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
  }
  h1, h2 {
    text-align: center;
  }
  h3 span{
    color:#8ace00
  }
  .form-section {
    margin-bottom: 20px;
  }
  label {
    display: block;
    margin-bottom: 5px;
  }
  input, select, textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  } 
  .label {
    background: #eee;
    border: 1px solid #ddd;
    padding: 5px;
    position: relative;
    height: 43px;
  }
  .label-picto {
    width: 52px;
    margin: 0 10px 0 0;
    width: 42px;
    padding: 5px;
    border-radius: 10px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .label-label {
    position: absolute;
    left: 50px;
    top: 0;
    border: 0px;
    background: transparent;
    top: 50%;
    transform: translate(0, -50%);
  }
  .label-check {
    accent-color: #8ace00;
    height: 21px;
    width: 21px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    margin: 0 0 0 20px;
    right: 10px;
  }

  .label-check--offered {
    height: 21px;
    width: 21px;
    accent-color: #8ace00;
  }
  .add-car-section {
    margin-top: 20px;
    padding: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  .car-image-preview {
    width: 150px;
    height: auto;
    margin-top: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  textarea {
    height: 100px;
  }
  .suggestions {
    list-style-type: none;
    padding: 0;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
  }
  .suggestion-item {
    padding: 10px;
    cursor: pointer;
  }
  .suggestion-item:hover {
    background-color: #f0f0f0;
  }
  .time-picker {
    display: flex;
    align-items: center;
  }
  .time-picker select {
    margin-left: 10px;
  }
</style>
  