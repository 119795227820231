<template>
    <div class="app-page">
        <div class="book-ride">
        <h1>Book a Ride</h1>
        <p>You're booking a ride for the vehicle with ID: {{ id }}</p>
    
        <form @submit.prevent="submitBooking">
            <div>
            <label for="name">Your Name</label>
            <input type="text" v-model="name" id="name" required />
            </div>
    
            <div>
            <label for="pickup">Pickup Location</label>
            <input type="text" v-model="pickup" id="pickup" required />
            </div>
    
            <div>
            <label for="destination">Destination</label>
            <input type="text" v-model="destination" id="destination" required />
            </div>
    
            <div>
            <label for="time">Pickup Time</label>
            <input type="datetime-local" v-model="pickupTime" id="time" required />
            </div>
    
            <button type="submit">Book Ride</button>
        </form>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'BookRide',
    props: {
      id: String, // Receives the id parameter from the route
    },
    data() {
      return {
        name: '',
        pickup: '',
        destination: '',
        pickupTime: '',
      };
    },
    methods: {
      submitBooking() {
        // Handle the booking submission
        const bookingDetails = {
          name: this.name,
          pickup: this.pickup,
          destination: this.destination,
          pickupTime: this.pickupTime,
          vehicleId: this.id,
        };
  
        // Here you would typically make an API call to save the booking
        console.log('Booking Details:', bookingDetails);
  
        // Example: Make an API request to submit the booking (for demonstration)
        // this.$axios.post('/api/book-ride', bookingDetails)
        //   .then(response => {
        //     console.log('Booking successful', response);
        //   })
        //   .catch(error => {
        //     console.error('Error booking ride', error);
        //   });
      },
    },
  };
  </script>
  
  <style scoped>
  .book-ride {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
  }
  
  h1 {
    text-align: center;
  }
  
  form {
    display: grid;
    grid-gap: 20px;
  }
  
  label {
    font-weight: bold;
  }
  
  input {
    width: 100%;
    padding: 8px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #45a049;
  }
  </style>
  