<template>
    <div class="app-page">
      <div class="login-container">
        <h1>Login</h1>
        <form @submit.prevent="login">
          <div class="form-group">
            <label for="email">Email:</label>
            <input v-model="email" type="email" id="email" placeholder="Enter your email" required />
          </div>
          <div class="form-group">
            <label for="password">Password:</label>
            <input v-model="password" type="password" id="password" placeholder="Enter your password" required />
          </div>
          <button class="btn btn--primary" type="submit">Login</button>
          <router-link to="/register" class="black-text">Not registered yet? Click here to register.</router-link>

        </form>
        <p class="error-message" v-if="errorMessage">{{ errorMessage }}</p>
      </div>

    </div>
  </template>
  
  <script>
import axios from 'axios';
import Cookies from 'js-cookie';

export default {
  mounted () {
    window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
  },
  data() {
    return {
      email: '',
      password: '',
      errorMessage: '',
      resp: []
    };
  },
  methods: {
    async login() {
      try {
        const response = await axios.post(
          'https://api.spawnaride.com/api/auth/login',
          {
            email: this.email,
            password: this.password,
          },
          {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": "application/json",
            },
          }
        );
        if(response?.data?.token) {
          const token = response.data.token;
          Cookies.set('authToken', token, { expires: 7 });
          this.$router.push({ name: 'Dashboard' });
        }
        else {
          this.errorMessage = 'Invalid email or password';
        }
      } catch (error) {
        this.errorMessage = 'Invalid email or password';
        console.error(error);
      }
    },
  }
};
  </script>
  
  <style scoped>
  .error-message{
    color: red;
  }
  .login-container {
    max-width: 400px;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
    min-height: 100vh;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  input {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
  }
  button {
    width: 100%;
  }
  </style>
  