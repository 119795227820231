<template>

    <!-- preloader
    ================================================== -->
    <div id="preloader" style="display: none!important;">
        <div id="loader" class="dots-jump">
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>


    <!-- header 
    ================================================== -->
    <header class="s-header">

        <div class="row">
            <div class="header-logo">
                <router-link class="site-logo" to="/">
                    <img src="/images/Spawn_logo_512px_white.png" alt="Homepage">
                    <div>
                      SpawnARide
                    </div>
                </router-link>
            </div>
    
            <nav class="header-nav-wrap">
              <ul class="header-main-nav">
                  <li class="current"><router-link class="smoothscroll" to="/#home" title="intro">Intro</router-link></li>
                  <li><router-link class="smoothscroll" to="/#about" title="about">À Propos</router-link></li>
                  <li><router-link class="smoothscroll" to="/#features" title="features">Fonctionnalités</router-link></li>
                  <li><router-link class="smoothscroll" to="/#pricing" title="pricing">Tarification</router-link></li>
                  <li><router-link class="smoothscroll" to="/#download" title="download">Télécharger</router-link></li>	
              </ul>
              <div class="header-cta">
                <router-link to="/login" class="btn btn--secondary header-cta__btn smoothscroll" style="margin-right: 15px; margin-bottom: 15px">Login</router-link>
                <router-link to="/register" class="btn btn--primary header-cta__btn smoothscroll">Créer un compte</router-link>
              </div>
            </nav> <!-- end header-nav-wrap -->
    
            <a class="header-menu-toggle" href="#"><span>Menu</span></a>
        </div>

    </header> <!-- end header -->
  </template>
  
  <script>
  export default {
    name: 'HeaderView',
    methods: {
      togglePreInscription() {
        // Implement functionality to handle pre-registration
        alert('Préinscription functionality to be implemented.');
      },
    },
  };
  </script>
  
  <style scoped lang="scss">
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #111;
    padding: 20px;
    .header-cta {
      margin: 7px 0;
    }
    .logo {
      h1 {
        color: #8ACE00;
      }
    }
    
    nav {
      ul {
        list-style: none;
        display: flex;
        margin: 0;
        padding: 0;
  
        li {
          margin: 0 15px;
  
          a {
            color: #fff;
            text-decoration: none;
  
            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
    
    .pre-inscription {
      button {
        background-color: #8ACE00;
        color: #fff;
        border: none;
        padding: 10px 20px;
        cursor: pointer;
        border-radius: 5px;
  
        &:hover {
          background-color: darken(#8ACE00, 10%);
        }
      }
    }
  }
  </style>
  