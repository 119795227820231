<template>
    <div class="app-page">
      <div class="terms">
        <h1>Conditions d'Utilisation de Spawnaride</h1>
        <p><strong>Date d'entrée en vigueur : 2024-11-01</strong></p>

        <h2>1. Acceptation des Conditions</h2>
        <p>En accédant ou en utilisant les services de Spawnaride, vous acceptez d'être lié par ces Conditions d'Utilisation. Si vous n'acceptez pas ces conditions, veuillez ne pas utiliser notre service.</p>

        <h2>2. Modifications des Conditions</h2>
        <p>Spawnaride se réserve le droit de modifier ces Conditions d'Utilisation à tout moment. Les modifications seront publiées sur cette page. Il est de votre responsabilité de consulter régulièrement cette page. Votre utilisation continue de l'application après la publication des modifications constitue votre acceptation des nouvelles conditions.</p>

        <h2>3. Informations sur l'Entreprise</h2>
        <p>Spawnaride est une entreprise dirigée par [Noms des dirigeants], spécialisée dans la mise en relation des utilisateurs pour des services de covoiturage. Nous nous engageons à fournir une plateforme sécurisée et fiable.</p>

        <h2>4. Utilisation de l'Application</h2>

        <h3>4.1. Éligibilité</h3>
        <p>Pour utiliser notre application, vous devez avoir au moins 18 ans ou être un mineur accompagné de votre parent ou tuteur légal. En utilisant l'application, vous déclarez et garantissez que vous remplissez ces conditions.</p>

        <h3>4.2. Compte Utilisateur</h3>
        <p>Pour accéder à certaines fonctionnalités de notre service, vous devrez créer un compte. Vous êtes responsable de la confidentialité de vos identifiants de connexion et de toutes les activités qui se produisent sous votre compte. Vous vous engagez à nous informer immédiatement de toute utilisation non autorisée de votre compte.</p>

        <h2>5. Obligations de l'Utilisateur</h2>
        <p>Vous vous engagez à ne pas :</p>
        <ul>
            <li>Utiliser l'application à des fins illégales ou non autorisées.</li>
            <li>Tenter d'accéder à des parties non autorisées de l'application ou de ses systèmes.</li>
            <li>Transmettre des virus, des chevaux de Troie ou tout autre code malveillant.</li>
            <li>Perturber ou interférer avec la sécurité de l'application ou de ses utilisateurs.</li>
            <li>Usurper l'identité de toute personne ou entité ou faussement déclarer votre affiliation à une personne ou entité.</li>
        </ul>

        <h2>6. Propriété Intellectuelle</h2>
        <p>Tous les droits de propriété intellectuelle liés à l'application, y compris, mais sans s'y limiter, les droits d'auteur, marques déposées et brevets, sont la propriété de Spawnaride. Vous ne devez pas reproduire, distribuer ou exploiter tout contenu de l'application sans notre autorisation écrite préalable.</p>

        <h2>7. Limitation de Responsabilité</h2>
        <p>Dans toute la mesure permise par la loi, Spawnaride ne pourra être tenu responsable des dommages directs, indirects, spéciaux, consécutifs ou punitifs découlant de votre utilisation de l'application. Nous ne garantissons pas que l'application sera toujours disponible, sans erreur ou sécurisée.</p>
        <p>En aucun cas, la responsabilité de Spawnaride ne dépassera le montant que vous avez payé pour accéder à notre service, le cas échéant.</p>

        <h2>8. Indemnisation</h2>
        <p>Vous acceptez d'indemniser et de défendre Spawnaride, ses dirigeants, employés et agents contre toutes réclamations, pertes, responsabilités, coûts et dépenses, y compris les honoraires d'avocat, résultant de votre utilisation de l'application ou de votre violation des présentes conditions.</p>

        <h2>9. Droit Applicable</h2>
        <p>Ces Conditions d'Utilisation sont régies par les lois de [Votre Pays]. Tout litige découlant de ces conditions sera soumis à la juridiction exclusive des tribunaux de [Votre Ville].</p>

        <h2>10. Résiliation</h2>
        <p>Nous nous réservons le droit de suspendre ou de résilier votre accès à l'application à tout moment, sans préavis, pour des raisons de violation de ces Conditions d'Utilisation ou pour d'autres raisons que nous jugeons appropriées.</p>

        <h2>11. Contact</h2>
        <p>Pour toute question concernant ces Conditions d'Utilisation, veuillez nous contacter: <router-link to="/contact-us">contact</router-link>.</p>

        <!-- Ajoutez les détails des conditions d'utilisation ici -->
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {};
    },
    watch: {
      $route() {
      // Push a new query parameter when the route changes
      this.$router.push({ query: { menuType: 'app' } });
    },
    },
    mounted () {
      this.$router.push({ query: { menuType: 'app' } });
    }
  };
  </script>
  
  <style scoped>
  .terms {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
  }
  </style>
  