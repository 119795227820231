<template>
    <div class="app-page">
        <div class="account-page">
        <header class="account-h`eader">
            <h1>Mon Compte</h1>
            <nav class="account-nav">
            <ul>
                <li>
                <router-link class="compte-lien" to="profile">Profil</router-link>
                </li>
                <li>
                <router-link class="compte-lien" to="driver-infos">Infos Conducteur</router-link>
                </li>
                <li>
                <router-link class="compte-lien" to="my-cars">My Cars</router-link>
                </li>
                <li>
                <router-link class="compte-lien" to="stats">Stats</router-link>
                </li>
                <li>
                <router-link class="compte-lien" to="notifications">Notifications</router-link>
                </li>
                <li>
                <router-link class="compte-lien" to="about">À Propos</router-link>
                </li>
                <li>
                <router-link class="compte-lien" to="impact">Notre Impact</router-link>
                </li>
                <li>
                <router-link class="compte-lien" to="terms">Conditions d'Utilisation</router-link>
                </li>
                <li>
                <router-link class="compte-lien" to="privacy">Accord de confidentialité</router-link>
                </li>
                <li @click="logout()">Déconnexion</li>

                <li>
                <router-link class="compte-lien" to="delete-account">Supprimer mon compte</router-link>
                </li>
            </ul>
            </nav>
        </header>
    
        <router-view class="account-content" />
        </div>`
    </div>`
  </template>
  
  <script>
  import Cookies from 'js-cookie';
  export default {
    methods: {
      logout() {
        Cookies.remove('authToken');
        this.$router.push({ name: 'LoginPage' });
      },
    },
    watch: {
      $route() {
        this.$router.push({ query: { menuType: 'app' } });
      },
    },
    mounted () {
      this.$router.push({ query: { menuType: 'app' } });
    }
  };
  </script>
  
  <style scoped>

  .account-page {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
  }
  .compte-lien {
    color: #111;
  }
  .account-header {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .account-nav {
    margin-top: 20px;
  }
  
  .account-nav ul {
    list-style: none;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .account-nav li {
    cursor: pointer;
    padding: 10px 15px;
    border-radius: 5px;
    transition: background-color 0.3s;
  }
  
  .account-nav li:hover {
    background-color: #f0f0f0;
  }
  
  .account-content {
    margin-top: 20px;
  }
  </style>
  