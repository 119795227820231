<template>
  <div class="app-page">
    <div class="impact">
      <header>
    <h1>Notre Impact</h1>
    </header>

    <div class="container">
        <div class="impact-section">
            <h2>Engagement Communautaire</h2>
            <img src="images/community-impact.jpg" alt="Engagement Communautaire">
            <div class="impact-card">
                <h3>Nous redonnons à la communauté</h3>
                <p>Nous collaborons avec des organisations locales pour soutenir des initiatives qui améliorent la qualité de vie dans nos communautés.</p>
            </div>
        </div>

        <div class="impact-section">
            <h2>Durabilité Environnementale</h2>
            <img src="images/sustainability.png" alt="Durabilité Environnementale">
            <div class="impact-card">
                <h3>Réduire notre empreinte carbone</h3>
                <p>Nous nous engageons à minimiser notre impact environnemental en adoptant des pratiques durables dans toutes nos opérations.</p>
            </div>
        </div>

        <div class="impact-section">
            <h2>Innovation Sociale</h2>
            <img src="images/social-innovation.png" alt="Innovation Sociale">
            <div class="impact-card">
                <h3>Promouvoir l'innovation</h3>
                <p>Nous soutenons des projets innovants qui répondent aux défis sociaux et qui améliorent la vie des gens.</p>
            </div>
        </div>

        <div class="impact-section">
            <h2>Partenariats Stratégiques</h2>
            <img src="images/partnerships.png" alt="Partenariats Stratégiques">
            <div class="impact-card">
                <h3>Collaboration avec des acteurs clés</h3>
                <p>Nous travaillons en partenariat avec des entreprises et des organisations pour maximiser notre impact et atteindre des objectifs communs.</p>
            </div>
        </div>
    </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  watch: {
    $route() {
    // Push a new query parameter when the route changes
    this.$router.push({ query: { menuType: 'app' } });
  },
  },
  mounted () {
    this.$router.push({ query: { menuType: 'app' } });
  }
};
</script>

<style scoped>
.impact {
  background-color: #f9f9f9;
}
body {
            font-family: Arial, sans-serif;
            margin: 0;
            padding: 0;
            background-color: #f4f4f4;
            color: #333;
        }
        header {
            background-color: #8ACE00;
            color: white;
            padding: 20px;
            text-align: center;
        }
        h1 {
          padding: 50px 0 40px;
            margin: 0;
            font-size: 36px;
        }
        h2 {
            font-size: 28px;
            margin-top: 20px;
            color: #8ACE00;
        }
        .container {
            width: 80%;
            margin: auto;
            overflow: hidden;
            padding: 20px;
        }
        .impact-section {
            background: white;
            padding: 20px;
            margin: 20px 0;
            border-radius: 5px;
            box-shadow: 0 2px 5px rgba(0,0,0,0.1);
        }
        .impact-section img {
            width: 100%;
            border-radius: 5px;
        }
        .impact-card {
            display: flex;
            flex-direction: column;
            align-items: center;
            text-align: center;
            padding: 10px;
        }
        .impact-card h3 {
            margin-top: 10px;
        }
        footer {
            background-color: #333;
            color: white;
            text-align: center;
            padding: 10px;
            position: relative;
            bottom: 0;
            width: 100%;
        }
</style>
