<template>
    <div class="rides-page">
      <h2>Rides</h2>
  
      <!-- Incoming Booked Rides as Passenger -->
      <div class="rides-section">
        <h3>Incoming Booked Rides (Passenger)</h3>
        <div class="rides-list">
          <div 
            class="ride-item" 
            v-for="ride in bookedRides" 
            :key="ride.id"
          >
            <h4>{{ ride.title }}</h4>
            <p>{{ ride.description }}</p>
          </div>
        </div>
      </div>
  
      <!-- Incoming Rides as Driver -->
      <div class="rides-section">
        <h3>Incoming Rides (Driver)</h3>
        <div class="rides-list">
          <div 
            class="ride-item" 
            v-for="ride in driverRides" 
            :key="ride.id"
          >
            <h4>{{ ride.title }}</h4>
            <p>{{ ride.description }}</p>
          </div>
        </div>
      </div>
  
      <!-- Completed or Past Rides -->
      <div class="rides-section">
        <h3>Completed or Past Rides</h3>
        <div class="rides-list">
          <div 
            class="ride-item" 
            v-for="ride in completedRides" 
            :key="ride.id"
          >
            <h4>{{ ride.title }}</h4>
            <p>{{ ride.description }}</p>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "RidesPage",
    data() {
      return {
        // Incoming booked rides as passenger
        bookedRides: [
          { id: 1, title: "Ride to City Center", description: "On Oct 20 at 10:30 AM" },
          { id: 2, title: "Ride to Airport", description: "On Oct 22 at 6:00 AM" },
          // Add more incoming booked rides
        ],
        
        // Incoming rides as driver
        driverRides: [
          { id: 3, title: "Pick-up at North Park", description: "On Oct 19 at 3:00 PM" },
          { id: 4, title: "Ride to Downtown", description: "On Oct 21 at 2:30 PM" },
          // Add more incoming driver rides
        ],
        
        // Completed or past rides
        completedRides: [
          { id: 5, title: "Completed Ride to Suburbs", description: "Completed on Oct 12" },
          { id: 6, title: "Completed Airport Ride", description: "Completed on Oct 15" },
          // Add more completed rides
        ]
      };
    },
    watch: {
      $route() {
      // Push a new query parameter when the route changes
      this.$router.push({ query: { menuType: 'app' } });
    },
    },
    mounted () {
      this.$router.push({ query: { menuType: 'app' } });
    }
  };
  </script>
  
  <style scoped>
  .rides-page {
    padding: 20px;
  }
  
  .rides-section {
    margin-bottom: 40px;
  }
  
  .rides-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .ride-item {
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 8px;
  }
  
  h3 {
    color: #8ACE00;
  }
  
  h4 {
    margin-bottom: 5px;
  }
  
  .ride-item p {
    color: #666;
  }
  </style>
  