<template>
    <div class="app-page">
    <div class="search-page">
      <h1 class="title">Find Your Ride</h1>
      <form @submit.prevent="submitSearch" class="search-form">
        <div class="form-group form-group__from">
          <div class="form-group__from__input-container">
            <input
                  type="text"
                  v-model="fromCitySearch"
                  @input="filterFromCities"
                  id="fromCity"
                  placeholder="From"
                  class="input form-group__from__input"
                  required
                />
                <i v-if="selectedFromCity" @click="clearCityFrom()" class="fas fa-window-close form-group__from__input__close"></i>
          </div>

          <ul v-if="filteredFromCities.length" class="suggestions">
            <li v-for="city in filteredFromCities" :key="city.city_code" @click="selectFromCity(city)" class="suggestion-item">
              {{ city.en.city }} ({{ city.en.province }})
            </li>
          </ul>
        </div>
        <div class="form-group__switch" @click="switchFromTo()">
          <img src="@/assets/images/swap_brat.png"/>
        </div>
        <div class="form-group form-group__to">
          <div class="form-group__from__input-container">
            <input
              type="text"
              v-model="toCitySearch"
              @input="filterToCities"
              id="toCity"
              placeholder="To"
              class="input form-group__from__input"
              required
            />
            <i v-if="selectedToCity" @click="clearCityTo()" class="fas fa-window-close form-group__from__input__close"></i>
          </div>
          <ul v-if="filteredToCities.length" class="suggestions">
            <li v-for="city in filteredToCities" :key="city.city_code" @click="selectToCity(city)" class="suggestion-item">
              {{ city.en.city }} ({{ city.en.province }})
            </li>
          </ul>
        </div>
        <div class="form-group form-group__when">
          <input type="date" v-model="dateFrom" id="dateFrom" class="input" required />
        </div>
<!--   
        <div class="form-group">
          <label for="dateTo">To Date:</label>
          <input type="date" v-model="dateTo" id="dateTo" class="input" required />
        </div> -->
  
        <button type="submit" class="button form-group__button">Search</button>
      </form>
      <div v-if="loading" class="loading"><img class="loaging-page__loader" src="@/assets/images/loader.gif"/></div>
      <div v-else-if="searchResults.length" class="results">
        <h2 class='result-title'>Search Results</h2>
        <div class="results-grid">
          <router-link :to="'/book-ride/' + result.ride_id" class="result-card" v-for="result in searchResults" :key="result.id">
            <div class="driver-info">
              <img :src="'https://spawnaride.com/users-pictures/' + result.user_img_url" alt="Driver's Picture" class="driver-picture" />
              <div class="driver-details">
                <h3>{{ result.user_name }}</h3>
                <p class="rating">Rating: {{ result.driverRating }} ★</p>
                <p class="Driven">Driven: {{ result.driverRating }}</p>
              </div>
            </div>
            <div class="ride-details">
              <p>{{ result.origin_city }} to {{ result.end_city }}</p>   
              <p class="departure-time">Leaving: {{ formatDepartureTime(result.departure_datetime) }}</p>

              <p>Available Seats: {{ result.min_available_seats }}</p>
              <p>Fare: ${{ result.fare }}</p>

              <p>Baggage Size: {{ result.baggageSize }}</p>
              <p>Driven Roads: {{ result.drivenRoads }}</p>
              "winter_tires": {{result.winter_tires}},
              "bicycles": {{result.bicycles}},
              "snowboard": {{result.snowboard}},
              "animals": {{result.animals}},
              "air_conditioning": {{result.air_conditioning}},
              "smoking_allowed": {{result.smoking_allowed}},
              segment_level: {{result.segment_level}}
              "notes": {{result.notes}},,        
            </div>

            <div class="car-info">
              <img :src="'https://spawnaride.com/cars-pictures/' + result.user_id + '/' + result.car_image_url" alt="Car's Picture" class="car-picture" />
              <p class="">
                {{ result.car_make }} {{ result.car_model }} {{ result.car_year }}
              </p>
            </div>
          </router-link>
        </div>
      </div> 
      
    </div>
</div>

  </template>
  
  <script>
  import axios from 'axios';
  import Cookies from 'js-cookie';
  
  export default {
    data() {
      return {
        token: Cookies.get('authToken'),
        cities: [
            // Quebec Cities
            {
                city_code: 'MTL',
                province_code: 'QC',
                fr: { city: 'Montréal', province: 'Québec' },
                en: { city: 'Montreal', province: 'Quebec' }
            },
            {
                city_code: 'LAV',
                province_code: 'QC',
                fr: { city: 'Laval', province: 'Québec' },
                en: { city: 'Laval', province: 'Quebec' }
            },
            {
                city_code: 'QUE',
                province_code: 'QC',
                fr: { city: 'Québec', province: 'Québec' },
                en: { city: 'Quebec City', province: 'Quebec' }
            },
            {
                city_code: 'GAT',
                province_code: 'QC',
                fr: { city: 'Gatineau', province: 'Québec' },
                en: { city: 'Gatineau', province: 'Quebec' }
            },
            {
                city_code: 'LON',
                province_code: 'QC',
                fr: { city: 'Longueuil', province: 'Québec' },
                en: { city: 'Longueuil', province: 'Quebec' }
            },
            {
                city_code: 'SHE',
                province_code: 'QC',
                fr: { city: 'Sherbrooke', province: 'Québec' },
                en: { city: 'Sherbrooke', province: 'Quebec' }
            },
            {
                city_code: 'TR',
                province_code: 'QC',
                fr: { city: 'Trois-Rivières', province: 'Québec' },
                en: { city: 'Trois-Rivières', province: 'Quebec' }
            },
            {
                city_code: 'SAG',
                province_code: 'QC',
                fr: { city: 'Saguenay', province: 'Québec' },
                en: { city: 'Saguenay', province: 'Quebec' }
            },

            // Ontario Cities
            {
                city_code: 'TOR',
                province_code: 'ON',
                fr: { city: 'Toronto', province: 'Ontario' },
                en: { city: 'Toronto', province: 'Ontario' }
            },
            {
                city_code: 'KNG',
                province_code: 'ON',
                fr: { city: 'Kingston', province: 'Ontario' },
                en: { city: 'Kingston', province: 'Ontario' }
            },
            {
                city_code: 'OTT',
                province_code: 'ON',
                fr: { city: 'Ottawa', province: 'Ontario' },
                en: { city: 'Ottawa', province: 'Ontario' }
            },
            {
                city_code: 'MIS',
                province_code: 'ON',
                fr: { city: 'Mississauga', province: 'Ontario' },
                en: { city: 'Mississauga', province: 'Ontario' }
            },
            {
                city_code: 'BRA',
                province_code: 'ON',
                fr: { city: 'Brampton', province: 'Ontario' },
                en: { city: 'Brampton', province: 'Ontario' }
            },
            {
                city_code: 'LON',
                province_code: 'ON',
                fr: { city: 'London', province: 'Ontario' },
                en: { city: 'London', province: 'Ontario' }
            },
            {
                city_code: 'KIC',
                province_code: 'ON',
                fr: { city: 'Kitchener', province: 'Ontario' },
                en: { city: 'Kitchener', province: 'Ontario' }
            },
            {
                city_code: 'WIN',
                province_code: 'ON',
                fr: { city: 'Windsor', province: 'Ontario' },
                en: { city: 'Windsor', province: 'Ontario' }
            },
            {
                city_code: 'NIAG',
                province_code: 'ON',
                fr: { city: 'Niagara Falls', province: 'Ontario' },
                en: { city: 'Niagara Falls', province: 'Ontario' }
            },
            {
            "city_code": "VAN",
            "province_code": "BC",
            "fr": { "city": "Vancouver", "province": "Colombie-Britannique" },
            "en": { "city": "Vancouver", "province": "British Columbia" }
            },
            {
            "city_code": "NOU",
            "province_code": "BC",
            "fr": { "city": "North Vancouver", "province": "Colombie-Britannique" },
            "en": { "city": "North Vancouver", "province": "British Columbia" }
            },
            {
            "city_code": "SOU",
            "province_code": "BC",
            "fr": { "city": "South Vancouver", "province": "Colombie-Britannique" },
            "en": { "city": "South Vancouver", "province": "British Columbia" }
            },
            {
            "city_code": "WES",
            "province_code": "BC",
            "fr": { "city": "West Vancouver", "province": "Colombie-Britannique" },
            "en": { "city": "West Vancouver", "province": "British Columbia" }
            },
            {
            "city_code": "RIC",
            "province_code": "BC",
            "fr": { "city": "Richmond", "province": "Colombie-Britannique" },
            "en": { "city": "Richmond", "province": "British Columbia" }
            },
            {
            "city_code": "BUR",
            "province_code": "BC",
            "fr": { "city": "Burnaby", "province": "Colombie-Britannique" },
            "en": { "city": "Burnaby", "province": "British Columbia" }
            },
            {
            "city_code": "DEL",
            "province_code": "BC",
            "fr": { "city": "Delta", "province": "Colombie-Britannique" },
            "en": { "city": "Delta", "province": "British Columbia" }
            },
            {
            "city_code": "SUR",
            "province_code": "BC",
            "fr": { "city": "Surrey", "province": "Colombie-Britannique" },
            "en": { "city": "Surrey", "province": "British Columbia" }
            },
            {
            "city_code": "LAD",
            "province_code": "BC",
            "fr": { "city": "Ladner", "province": "Colombie-Britannique" },
            "en": { "city": "Ladner", "province": "British Columbia" }
            },
            {
            "city_code": "POR",
            "province_code": "BC",
            "fr": { "city": "Port Coquitlam", "province": "Colombie-Britannique" },
            "en": { "city": "Port Coquitlam", "province": "British Columbia" }
            },
            {
            "city_code": "COQ",
            "province_code": "BC",
            "fr": { "city": "Coquitlam", "province": "Colombie-Britannique" },
            "en": { "city": "Coquitlam", "province": "British Columbia" }
            },
            {
            "city_code": "MAP",
            "province_code": "BC",
            "fr": { "city": "Maple Ridge", "province": "Colombie-Britannique" },
            "en": { "city": "Maple Ridge", "province": "British Columbia" }
            },
            {
            "city_code": "LANG",
            "province_code": "BC",
            "fr": { "city": "Langley", "province": "Colombie-Britannique" },
            "en": { "city": "Langley", "province": "British Columbia" }
            },
            {
            "city_code": "PITT",
            "province_code": "BC",
            "fr": { "city": "Pitt Meadows", "province": "Colombie-Britannique" },
            "en": { "city": "Pitt Meadows", "province": "British Columbia" }
            },
            {
            "city_code": "ABO",
            "province_code": "BC",
            "fr": { "city": "Abbotsford", "province": "Colombie-Britannique" },
            "en": { "city": "Abbotsford", "province": "British Columbia" }
            },
            {
            "city_code": "CAL",
            "province_code": "AB",
            "fr": { "city": "Calgary", "province": "Alberta" },
            "en": { "city": "Calgary", "province": "Alberta" }
            },
            {
            "city_code": "EDM",
            "province_code": "AB",
            "fr": { "city": "Edmonton", "province": "Alberta" },
            "en": { "city": "Edmonton", "province": "Alberta" }
            },
            {
            "city_code": "RED",
            "province_code": "AB",
            "fr": { "city": "Red Deer", "province": "Alberta" },
            "en": { "city": "Red Deer", "province": "Alberta" }
            },
            {
            "city_code": "LAC",
            "province_code": "AB",
            "fr": { "city": "Lacombe", "province": "Alberta" },
            "en": { "city": "Lacombe", "province": "Alberta" }
            },
            {
            "city_code": "LETH",
            "province_code": "AB",
            "fr": { "city": "Lethbridge", "province": "Alberta" },
            "en": { "city": "Lethbridge", "province": "Alberta" }
            },
            {
            "city_code": "AIR",
            "province_code": "AB",
            "fr": { "city": "Airdrie", "province": "Alberta" },
            "en": { "city": "Airdrie", "province": "Alberta" }
            },
            {
            "city_code": "MED",
            "province_code": "AB",
            "fr": { "city": "Medicine Hat", "province": "Alberta" },
            "en": { "city": "Medicine Hat", "province": "Alberta" }
            },
            {
            "city_code": "COLE",
            "province_code": "AB",
            "fr": { "city": "Cold Lake", "province": "Alberta" },
            "en": { "city": "Cold Lake", "province": "Alberta" }
            },
            {
            "city_code": "GRAN",
            "province_code": "AB",
            "fr": { "city": "Grand Prairie", "province": "Alberta" },
            "en": { "city": "Grande Prairie", "province": "Alberta" }
            },
            {
            "city_code": "BEA",
            "province_code": "AB",
            "fr": { "city": "Fort McMurray", "province": "Alberta" },
            "en": { "city": "Fort McMurray", "province": "Alberta" }
            },
            {
            "city_code": "STAL",
            "province_code": "AB",
            "fr": { "city": "St. Albert", "province": "Alberta" },
            "en": { "city": "St. Albert", "province": "Alberta" }
            },
            {
            "city_code": "OKOT",
            "province_code": "AB",
            "fr": { "city": "Okotoks", "province": "Alberta" },
            "en": { "city": "Okotoks", "province": "Alberta" }
            },
            {
            "city_code": "COCHR",
            "province_code": "AB",
            "fr": { "city": "Cochrane", "province": "Alberta" },
            "en": { "city": "Cochrane", "province": "Alberta" }
            },
            {
            "city_code": "DRUM",
            "province_code": "AB",
            "fr": { "city": "Drumheller", "province": "Alberta" },
            "en": { "city": "Drumheller", "province": "Alberta" }
            },
            {
            "city_code": "BRO",
            "province_code": "AB",
            "fr": { "city": "Brooks", "province": "Alberta" },
            "en": { "city": "Brooks", "province": "Alberta" }
            },
            {
            "city_code": "REG",
            "province_code": "SK",
            "fr": { "city": "Regina", "province": "Saskatchewan" },
            "en": { "city": "Regina", "province": "Saskatchewan" }
            },
            {
            "city_code": "SASK",
            "province_code": "SK",
            "fr": { "city": "Saskatoon", "province": "Saskatchewan" },
            "en": { "city": "Saskatoon", "province": "Saskatchewan" }
            },
            {
            "city_code": "PRIN",
            "province_code": "SK",
            "fr": { "city": "Prince Albert", "province": "Saskatchewan" },
            "en": { "city": "Prince Albert", "province": "Saskatchewan" }
            },
            {
            "city_code": "MOOSE",
            "province_code": "SK",
            "fr": { "city": "Moose Jaw", "province": "Saskatchewan" },
            "en": { "city": "Moose Jaw", "province": "Saskatchewan" }
            },
            {
            "city_code": "SWIFT",
            "province_code": "SK",
            "fr": { "city": "Swift Current", "province": "Saskatchewan" },
            "en": { "city": "Swift Current", "province": "Saskatchewan" }
            },
            {
            "city_code": "YORK",
            "province_code": "SK",
            "fr": { "city": "Yorkton", "province": "Saskatchewan" },
            "en": { "city": "Yorkton", "province": "Saskatchewan" }
            },
            {
            "city_code": "ESTEV",
            "province_code": "SK",
            "fr": { "city": "Estevan", "province": "Saskatchewan" },
            "en": { "city": "Estevan", "province": "Saskatchewan" }
            },
            {
            "city_code": "NIP",
            "province_code": "SK",
            "fr": { "city": "Nipawin", "province": "Saskatchewan" },
            "en": { "city": "Nipawin", "province": "Saskatchewan" }
            },
            {
            "city_code": "HUMB",
            "province_code": "SK",
            "fr": { "city": "Humboldt", "province": "Saskatchewan" },
            "en": { "city": "Humboldt", "province": "Saskatchewan" }
            },
            {
            "city_code": "WAT",
            "province_code": "SK",
            "fr": { "city": "Watrous", "province": "Saskatchewan" },
            "en": { "city": "Watrous", "province": "Saskatchewan" }
            },
            {
            "city_code": "SHELL",
            "province_code": "SK",
            "fr": { "city": "Shellbrook", "province": "Saskatchewan" },
            "en": { "city": "Shellbrook", "province": "Saskatchewan" }
            },
            {
            "city_code": "LLOY",
            "province_code": "SK",
            "fr": { "city": "Lloydminster", "province": "Saskatchewan" },
            "en": { "city": "Lloydminster", "province": "Saskatchewan" }
            },
            {
            "city_code": "BATTLE",
            "province_code": "SK",
            "fr": { "city": "Battleford", "province": "Saskatchewan" },
            "en": { "city": "Battleford", "province": "Saskatchewan" }
            },
            {
            "city_code": "MART",
            "province_code": "SK",
            "fr": { "city": "Martensville", "province": "Saskatchewan" },
            "en": { "city": "Martensville", "province": "Saskatchewan" }
            },
            {
            "city_code": "WPG",
            "province_code": "MB",
            "fr": { "city": "Winnipeg", "province": "Manitoba" },
            "en": { "city": "Winnipeg", "province": "Manitoba" }
            },
            {
            "city_code": "BRAND",
            "province_code": "MB",
            "fr": { "city": "Brandon", "province": "Manitoba" },
            "en": { "city": "Brandon", "province": "Manitoba" }
            },
            {
            "city_code": "STE",
            "province_code": "MB",
            "fr": { "city": "Steinbach", "province": "Manitoba" },
            "en": { "city": "Steinbach", "province": "Manitoba" }
            },
            {
            "city_code": "THOMP",
            "province_code": "MB",
            "fr": { "city": "Thompson", "province": "Manitoba" },
            "en": { "city": "Thompson", "province": "Manitoba" }
            },
            {
            "city_code": "DAUPH",
            "province_code": "MB",
            "fr": { "city": "Dauphin", "province": "Manitoba" },
            "en": { "city": "Dauphin", "province": "Manitoba" }
            },
            {
            "city_code": "FLIN",
            "province_code": "MB",
            "fr": { "city": "Flin Flon", "province": "Manitoba" },
            "en": { "city": "Flin Flon", "province": "Manitoba" }
            },
            {
            "city_code": "MORD",
            "province_code": "MB",
            "fr": { "city": "Morden", "province": "Manitoba" },
            "en": { "city": "Morden", "province": "Manitoba" }
            },
            {
            "city_code": "PORT",
            "province_code": "MB",
            "fr": { "city": "Portage la Prairie", "province": "Manitoba" },
            "en": { "city": "Portage la Prairie", "province": "Manitoba" }
            },
            {
            "city_code": "SELK",
            "province_code": "MB",
            "fr": { "city": "Selkirk", "province": "Manitoba" },
            "en": { "city": "Selkirk", "province": "Manitoba" }
            },
            {
            "city_code": "GIMLI",
            "province_code": "MB",
            "fr": { "city": "Gimli", "province": "Manitoba" },
            "en": { "city": "Gimli", "province": "Manitoba" }
            },
            {
            "city_code": "NEEP",
            "province_code": "MB",
            "fr": { "city": "Neepawa", "province": "Manitoba" },
            "en": { "city": "Neepawa", "province": "Manitoba" }
            },
            {
            "city_code": "WINNI",
            "province_code": "MB",
            "fr": { "city": "Winnipeg Beach", "province": "Manitoba" },
            "en": { "city": "Winnipeg Beach", "province": "Manitoba" }
            },
            {
            "city_code": "BIRT",
            "province_code": "MB",
            "fr": { "city": "Birtle", "province": "Manitoba" },
            "en": { "city": "Birtle", "province": "Manitoba" }
            },
            {
            "city_code": "CARB",
            "province_code": "MB",
            "fr": { "city": "Carberry", "province": "Manitoba" },
            "en": { "city": "Carberry", "province": "Manitoba" }
            }
  
        ],
        fromCitySearch: '',
        toCitySearch: '',
        filteredFromCities: [],
        filteredToCities: [],
        selectedFromCity: '',
        selectedToCity: '',
        dateFrom: this.getCurrentDate(), // Set default to today
        dateTo: '', // No default for To Date
        searchResults: [],
      };
    },
    created() {
      // this.loadCities();
    },
    methods: {
      switchFromTo () {
        const fromCitySearch = this.fromCitySearch;

        const toCitySearch = this.toCitySearch;

        const selectedFromCity = this.selectedFromCity;
        const selectedToCity = this.selectedToCity;
        
        this.fromCitySearch=toCitySearch;
        this.toCitySearch=fromCitySearch;
        this.selectedFromCity=selectedToCity;
        this.selectedToCity=selectedFromCity;
      },
      isLoged () {
        console.log(this.token);
        return this.token;
      },
      formatDepartureTime (dateTime) {
        const departureDate = new Date(dateTime);
        const today = new Date();
        const tomorrow = new Date();
        tomorrow.setDate(today.getDate() + 1);
        
        if (departureDate.toDateString() === tomorrow.toDateString()) {
          return "Tomorrow at " + departureDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        } else {
          return departureDate.toLocaleString(); // or any other format you prefer
        }
      },
      loadCities() {
        this.filteredFromCities = this.cities; // Initialize with all cities
        this.filteredToCities = this.cities; // Initialize with all cities
      },
  filterFromCities() {
    if (this.fromCitySearch) {
      this.filteredFromCities = this.cities.filter(city =>
        city.en.city.toLowerCase().includes(this.fromCitySearch.toLowerCase()) ||
        city.fr.city.toLowerCase().includes(this.fromCitySearch.toLowerCase())
      );
      if(this?.filteredFromCities[0]?.en?.city && this.filteredFromCities.length === 1) {
        this.selectFromCity(this.filteredFromCities[0]);
      }
    } else {
      this.filteredFromCities = this.cities; // Reset to all cities if input is empty
    }
  },
  filterToCities() {
    if (this.toCitySearch) {
      this.filteredToCities = this.cities.filter(city =>
        city.en.city.toLowerCase().includes(this.toCitySearch.toLowerCase()) ||
        city.fr.city.toLowerCase().includes(this.toCitySearch.toLowerCase())
      );
      if(this?.filteredToCities[0]?.en?.city && this.filteredToCities.length === 1) {
        this.selectToCity(this.filteredToCities[0]);
      }
    } else {
      this.filteredToCities = this.cities; // Reset to all cities if input is empty
    }
  },
      clearCityFrom() {
        this.fromCitySearch = undefined; // Set the input field to the selected city
        this.selectedFromCity = undefined; // Store the selected city code
        this.filteredFromCities = []; // Clear suggestions
      },
      clearCityTo() {
        this.toCitySearch = undefined; // Set the input field to the selected city
        this.selectedToCity = undefined; // Store the selected city code
        this.filteredFromCities = []; // Clear suggestions
      },
      selectFromCity(city) {
        this.fromCitySearch = city.en.city; // Set the input field to the selected city
        this.selectedFromCity = city.en.city; // Store the selected city code
        this.filteredFromCities = []; // Clear suggestions
      },
      selectToCity(city) {
        this.toCitySearch = city.en.city; // Set the input field to the selected city
        this.selectedToCity = city.en.city; // Store the selected city code
        this.filteredToCities = []; // Clear suggestions
      },
      async submitSearch() {
        this.loading = true;
        if (!this.selectedFromCity || !this.selectedToCity) {
          alert("Please select both from and to cities.");
          return;
        }

        this.loading = true; // Set loading state to true

        try {
          // const response = await axios.post('http://localhost:3009/api/rides/search-routes', {
          const response = await axios.post('https://api.spawnaride.com/api/rides/search-routes', {
            originCity: this.selectedFromCity,
            destinationCity: this.selectedToCity,
            departureDate: this.dateFrom
          });

          this.searchResults = response.data;
        } catch (error) {
          console.error("Error fetching search results:", error);
          alert("Failed to fetch search results. Please try again later.");
        } finally {
          this.loading = false;
        }
      },
      getCurrentDate() {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
        const yyyy = today.getFullYear();
        return `${yyyy}-${mm}-${dd}`;
      },
    },
    watch: {
      $route() {
        if(this.isLoged()) this.$router.push({ query: { ...this.$route.query, menuType: 'app' } });
      },
    },
    mounted () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      const from = this.$route?.query?.selectedFromCity || null;
      const to = this.$route?.query?.selectedToCity || null;
      const dateFrom = this.$route?.query?.dateFrom || null;
      if(this.isLoged()) this.$router.push({ query: { ...this.$route.query, menuType: 'app' } });
      if (from && to) {
        this.fromCitySearch=from
        this.toCitySearch=to;
        this.selectedFromCity=from;
        this.selectedToCity=to;
        this.dateFrom = dateFrom || this.dateFrom;
        this.submitSearch();
      }

    }
  };
  </script>
  
  <style scoped>
  .loaging-page__loader {
    width: 46px;
  }
  .results {
  margin-top: 20px;
  padding: 10px;
}
.search-result {

}
.results-grid {

}

.result-card {
  color:initial;

  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.2s;
  width: 100%;
  display: flex;
  margin: 0 0 15px;
}
.result-card:hover {
  color:initial;
}
/* .result-card:hover {
  transform: scale(1.02);
} */
.car-info {
    padding: 10px;
    width: 200px;
    float: left;
}

.car-picture {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 15px;
}
.driver-info {
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  width: 200px;
  float: left;
  position: relative;
}
.driver-info:after {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
    width: 1px;
    height: 80%;
    background: #bebebe;
    content: '';
}
.driver-picture {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  margin-right: 15px;
}

.driver-details {
  
}
.driver-details h3 {
  margin: 0;  
}
.rating {
  color: #f39c12; /* Gold color for rating */
}

.ride-details {
  padding: 10px;
  width: calc(100% - 400px);
  float: left;
  position: relative;
}
.ride-details:after {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
    width: 1px;
    height: 80%;
    background: #bebebe;
    content: '';
}
.ride-details p {
  margin: 5px 0;
  font-size: 14px;
}

.departure-time {
  font-weight: bold;
  color: #2980b9; /* Blue color for departure time */
}
  .search-page {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
  }
  
  .title {
    text-align: center;
    color: #333;
  }
  .result-title {
    margin: 0 0 20px;
    padding: 0;
  }
  .search-form {
    width: 100%;
    display: inline-block;
    background: rgb(228, 228, 228);
    border-radius: 10px;
    margin: 0;
    padding: 0px;
    height: auto;
    min-height: 0;
  }
  
  .form-group {
  }
  .form-group__from {
    width: calc((100% - 400px) / 2);
    float: left;
    display: inline-block;
    margin: 0 10px 0 10px;

  }
  .form-group__from__input-container {
    width: 100%;
    position: relative;
    padding: 0;
    margin: 10px 0 10px 0;
  }
  .form-group__from__input {
    position: relative;
    left: 0;
    top: 0;
    margin: 0;
    padding: 0;
  }
  .form-group__from__input__close {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    padding: 20px 15px;
    font-size: 20px;
  }
  .form-group__switch {
    width: 60px;
    float: left;
    display: inline-block;
    margin: 15px 10px 0 20px;
    border-radius: 10px;
    padding: 10px 20px 0px 10px;
    margin: 15px 0;
  }
  .form-group__to {
    width: calc((100% - 400px) / 2);
    float: left;
    display: inline-block;
    margin: 0 10px 0 0;
  }
  .form-group__when {
    width: 140px;
    float: left;
    display: inline-block;
    margin: 0 20px 0 0;
    height: auto;
  }
  .form-group__when input {
    padding: 20px 10px;
    font-size: 16px;
    width: 140px;
    margin: 10px 0;
  }
  .form-group__button {
    width: 140px;
    float: right;
    margin: 13px 10px 0 0;
    height: auto;
    padding: 1px 0!important;
    display: inline-block;
    border-radius: 10px!important;
  }
  .input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
    background-color: #fff;
    border-radius: 10px;
  }
  ::placeholder {
      color: #000000;
      opacity: 1; /* Firefox */
    }

    ::-ms-input-placeholder { /* Edge 12 -18 */
      color: #000000;
    }
  
  .suggestions {
    list-style-type: none;
    padding: 0;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    position: absolute
  }
  
  .suggestion-item {
    padding: 10px;
    cursor: pointer;
  }
  
  .suggestion-item:hover {
    background-color: #f0f0f0;
  }
  
  .button {
    padding: 10px;
    border: none;
    border-radius: 4px;
    background-color: #8ACE00;
    cursor: pointer;
    transition: background-color 0.3s ease;
    color: #111;
  }
  
  .button:hover {
    background-color: #8ACE00;
  }
  
  .results {
    margin-top: 20px;
  }
  
  .results-list {
    list-style-type: none;
    padding: 0;
  }
  
  .result-item {
    padding: 10px;
    border-bottom: 1px solid #ddd;
  }
  
  .result-item:last-child {
    border-bottom: none;
  }
  @media only screen and (max-width:800px) {
    .search-form {
      position: relative;
      padding: 10px 0;
    }
    .form-group {
      width: calc(100% - 20px)!important;
      margin: 0 10px;
    }
    .form-group__from__input-container {
      margin: 0;
    }
    .form-group__switch {
      position: absolute;
      right: 90px;
      background: rgb(204 204 204);
      padding: 2px;
      top: 36px;
      z-index: 10;
      padding: 4px;
      transform: rotate(90deg);
      width: 42px;
      height: 42px;
    }
    .form-group__switch img {
      margin: 0;
      padding: 0;
      background-color: #444444;
      border-radius: 7px;
      padding: 5px;
      width: 100px;
    }
    .form-group__when {
      width: calc(100% - 20px)!important;
      margin: 0 10px;
    }
    #dateFrom {
      margin: 0;
      width: 100%;
    }
    .button.form-group__button {
      width: calc(100% - 20px);
    }
    .driver-info {
      width: 100%;
    }
    .ride-details {
      width: 100%;
    }
    .car-info {
      width: 100%;
    }
    .result-card {
      display: block;
    }
  }
  </style>
  