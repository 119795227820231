<template>
    <div class="app-page">
      <div class="container">
        <h1>Get Started</h1>
        <form @submit.prevent="registerUser">
          <!-- User Information -->
          <div class="form-section">
            <p class="error-message" v-if="registerError">There was an issue creating your account. Please double-check all fields and try again. If the problem persists, contact support for assistance.</p>
            <label for="name">First Name</label>
            <input v-model="user.firstName" type="text" placeholder="Enter your name" required>

            <label for="name">Last Name</label>
            <input v-model="user.name" type="text" placeholder="Enter your name" required>
    
            <label for="email">Email</label>
            <input v-model="user.email" type="email" placeholder="Enter your email" required>
    
            <label for="password">Password</label>
            <input v-model="user.password" type="password" placeholder="Enter your password" required>
    
            <label for="confirmPassword">Confirm Password</label>
            <input v-model="user.confirmPassword" type="password" placeholder="Confirm your password" required>
<!--     
            <label for="phone">Phone Number</label>
            <input v-model="user.phone_number" type="tel" placeholder="Enter your phone number" required> -->
          </div>
    
          <!-- Address Information -->
          <!-- <div class="form-section">
            <h2>Address Information</h2>
            <label for="streetNumber">Street Number</label>
            <input v-model="user.streetNumber" type="text" placeholder="Enter your street number" required>
    
            <label for="street">Street</label>
            <input v-model="user.street" type="text" placeholder="Enter your street" required>
    
            <label for="city">City</label>
            <input v-model="user.city" type="text" placeholder="Enter your city" required>
    
            <label for="province">Province/State</label>
            <input v-model="user.province" type="text" placeholder="Enter your province/state" required>
    
            <label for="country">Country</label>
            <input v-model="user.country" type="text" placeholder="Enter your country" required>
          </div> -->
    
          <!-- Driver Checkbox -->
          <div class="label">
            <img class="label-picto" src="/images/chauffer.png"/>
            <label for="smokingAllowed" class="label-label">Are you a driver?</label>
            <input class="label-check" id='smokingAllowed' v-model="user.isDriver" type="checkbox">
          </div>
          
          <!-- Address Information -->
          <div class="form-section" v-if="user.isDriver">
            <h2>Driver Information</h2>
            <label for="streetNumber">License Number</label>
            <input v-model="user.license_number" type="text" placeholder="Enter your license number" required>
          </div>

          <!-- Submit Button -->
          <div class="form-section">
            <button class="btn btn--primary home-content__btn" type="submit">Register</button>
          </div>
          <router-link to="/login" class="black-text">Already registered ? Click here to login.</router-link>
        </form>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import Cookies from 'js-cookie';

  export default {
    mounted () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    },
    data() {
      return {
        registerError: false,
        user: {
          firstName: '',
          name: '',
          email: '',
          password: '',
          confirmPassword: '',
          phone_number: '',
          streetNumber: '',
          street: '',
          city: '',
          province: '',
          country: '',
          isDriver: false,
          license_number: ''
        }
      };
    },
    methods: {
      registerUser() {
        // Password validation
        if (this.user.password !== this.user.confirmPassword) {
          alert("Passwords do not match!");
          return;
        }
  
        // Post user registration data to the API
        console.log('User Registration Data:', this.user);
  
        // Here you should use an API call to register the user
        // Example:

        axios.post('https://api.spawnaride.com/api/auth/register', this.user)
          .then(response => {
            if(response?.data?.token) {
              const token = response.data.token;
              Cookies.set('authToken', token, { expires: 7 });
              this.$router.push({ name: 'Dashboard' }); // Redirect to chat page
            }
            else {
              this.registerError = 1;
            } 
          })
          .catch(error => {
            console.error(error);
            // Handle registration errors
          });
      }
    }
  };
  </script>
  
  <style scoped>
    .label {
    background: #eee;
    border: 1px solid #ddd;
    padding: 5px;
    position: relative;
    height: 43px;
    margin-bottom: 20px;
  }
  .label-picto {
    width: 52px;
    margin: 0 10px 0 0;
    width: 42px;
    padding: 5px;
    border-radius: 10px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .label-label {
    position: absolute;
    left: 50px;
    top: 0;
    border: 0px;
    background: transparent;
    top: 50%;
    transform: translate(0, -50%);
  }
  .label-check {
    accent-color: #8ace00;
    height: 21px;
    width: 21px;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    margin: 0 0 0 20px;
    right: 10px;
  }
  .container {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
  }
  
  h1, h2 {
    text-align: center;
  }
  
  .form-section {
    margin-bottom: 20px;
  }
  .error-message {
    color: red;
  }
  label {
    display: block;
    margin-bottom: 5px;
  }
  
  input, button {
    width: 100%;
    padding: 10px;
  }
 
  </style>
  