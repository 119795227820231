<template>
    <div class="app-page">

        <div class="chat-page">
        <h1>Messages</h1>
        
        <!-- List of chat previews -->
        <div class="chat-list">
            <div 
            class="chat-item" :class="chat.not_seen ? 'new' : ''"
            v-for="chat in chats" 
            :key="chat.id"
            @click="goToChat(chat.conversation_id)"
            >
            <div class="chat-avatar">
                <img :src="'https://spawnaride.com/users-pictures/' + chat.other_user_image" alt="User avatar" />
            </div>
            <div class="chat-info">
                <h2>{{ chat.other_user_name }}</h2>
                <p class="last-message">{{ chat.last_message }}</p>
                <span class="timestamp">{{ formatDate(chat.last_message_date) }}</span>
            </div>
            </div>
        </div>
    </div>
</div>
  </template>
  
  <script>
  import axios from 'axios';
  import Cookies from 'js-cookie';

  export default {
    name: "ChatPage",
    data() {
      return {
        token: Cookies.get('authToken'),
        chats: [],
      };
    },
    methods: {
      async fetchChats() {
        try {
          const response = await axios.get('https://api.spawnaride.com/api/conversations', {
            headers: {
              Authorization: `Bearer ${this.token}` // Add the authorization header
            }
          });
          this.chats = response.data.conversations;
        } catch (error) {
          console.error('Error fetching chats:', error);
        }
      },
      goToChat(chatId) {
        this.$router.push({ name: "ChatDetail", params: { chatId } });
      },
      formatDate(timestamp) {
        return new Date(timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
      },
    },
    watch: {
      // Watch for changes in the $route object
      $route() {
        // Push a new query parameter when the route changes
        this.$router.push({ query: { menuType: 'app' } });
      },
    },
    mounted () {
      this.$router.push({ query: { menuType: 'app' } });
      this.fetchChats();
    }
  };
  </script>
  
  <style scoped>
  .chat-page {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
  }
  
  .chat-list {

  }
  
  .chat-item {
    display: flex;
    align-items: center;
    padding: 10px;
    background-color: #f5f5f5;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  .chat-item.new {
    background-color: #8ace00;
  }
  .chat-item.new:hover {
    background-color: #aaff00;
  }
  .chat-item:hover {
    background-color: #e0e0e0;
  }
  
  .chat-avatar img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    object-fit: cover;
  }
  
  .chat-info {
    margin-left: 15px;
  }
  
  .chat-info h2 {
    margin: 0;
    font-size: 16px;
    font-weight: bold;
  }
  
  .chat-info p {
    margin: 5px 0;
    color: #888;
    font-size: 14px;
  }
  
  .timestamp {
    font-size: 12px;
    color: #999;
  }
  </style>
  