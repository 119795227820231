<template>
  <div id="app">
    <HeaderView v-if='menuType === "default"'/>
    <main>
      <router-view />
    </main>
    <FooterView />
    <AppFooter  v-if='menuType !== "default"'/>

  </div>
  <notifications position="top right" />
</template>

<script>
  import HeaderView from './components/HeaderView.vue';
  import FooterView from './components/FooterView.vue';
  import AppFooter from './components/AppFooter.vue';

  export default {
    name: 'App',
    components: {
      HeaderView,
      FooterView,
      AppFooter
    },
    data () {
      return {

      }
    },
    computed: {
      // Access query params or route params
      menuType() {
        return this.$route.query.menuType || 'default'; // Accessing menuType from query
      }
    }
  };
</script>

<style lang="scss">
  @import 'styles/main.scss';
</style>
