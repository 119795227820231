<template>
    <div class="app-page">
        <div class="profile-page">
        <h1>Profile</h1>
        <div class="profile-card">
          <div alt="Profile Picture" class="profile-picture" :style="'background-image: url(' + user.profilePicture + ')'"></div>
          <input type="file" @change="onFileChange" accept="image/*">
          <div class="profile-details">
            <div class="form-group">
              <label for="name">Name</label>
              <input v-model="user.name" type="text" id="name" class="form-control">
            </div>

            <div class="form-group">
              <label for="email">Email</label>
              <input disabled v-model="user.email" type="email" id="email" class="form-control">
            </div>

            <div class="form-group">
              <label for="phone">Phone</label>
              <input v-model="user.phone_number" type="tel" id="phone" class="form-control">
            </div>

            <button @click="updateProfile" class="btn btn--primary">Update Profile</button>
          </div>
        </div>
      </div>
      <div v-if="isLoading" class="loaging-page"><img class="loaging-page__loader" src="@/assets/images/loader.gif"/></div>

    </div>
  </template>

  <script>
  import axios from 'axios';
  import Cookies from 'js-cookie';
  import { useNotification } from "@kyvg/vue3-notification";
  // import { BIconBack } from 'bootstrap-vue';
  const { notify } = useNotification()

  export default {
    data() {
      return {
        isLoading: false,
        token: Cookies.get('authToken'),
        formData: null,
        user: {
          name: '',
          email: '',
          phone_number: '',
          profilePicture: '/images/blank-profile.png',
        },
        profilePictureFile: null,
      };
    },
    mounted() {
      this.$router.push({ query: { menuType: 'app' } });
      this.fetchProfile();
    },
    methods: {
      // Fetch user profile details from the API
      async fetchProfile() {
        try {
          const response = await axios.get('https://api.spawnaride.com/api/users/get', {
            headers: {
              "Authorization": `Bearer ${this.token}`,
            },
          });
          this.user = response?.data?.user;
          this.user.profilePicture = response?.data?.user?.url ? 'https://spawnaride.com/users-pictures/' + response.data.user.url : '/images/blank-profile.png';
        } catch (error) {
          console.error('Error fetching profile:', error);
        }
      },
  
      // Handle profile picture change
      onFileChange(e) {
        const file = e.target.files[0];
        if (file) {
          this.profilePictureFile = file;
          this.user.profilePicture = URL.createObjectURL(file); // Show the preview
        }
      },
  
      // Update the profile details
      async updateProfile() {
        try {
          // Handle form data for image upload
          this.isLoading = true;
          const formData = new FormData();
          formData.append('name', this.user.name);
          formData.append('email', this.user.email);
          formData.append('phone_number', this.user.phone_number);
          if (this.profilePictureFile) {
            formData.append('profilePicture', this.profilePictureFile);
          }
          await axios.put('https://api.spawnaride.com/api/users/edit', formData, {
            headers: {
              "Authorization": `Bearer ${this.token}`,
              "Content-Type": "multipart/form-data",
            },
          });
          this.isLoading = false;
          notify({
            title: "Saved",
            text: "Profile updated successfully!",
          });
        } catch (error) {
          this.isLoading = false;
          notify({
            title: "Error ",
            text: "There was an error updating your profile.",
            type: 'warn',
          });
        }
      }
    },
    watch: {
      // Watch for changes in the $route object
      $route() {
        // Push a new query parameter when the route changes
        this.$router.push({ query: { menuType: 'app' } });
      },
    }
  };
  </script>
  
  <style scoped>
  .loaging-page {
    background-color: rgba(0,0,0,.7);
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 10000;
   }
   .loaging-page__loader {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100px;
   }
  .profile-page {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .profile-card {
    border: 1px solid #ddd;
    padding: 20px;
    border-radius: 10px;
  }
  
  .profile-picture {
    background-color: #111;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    width: 150px;
    height: 150px;
    border-radius: 150px;
    margin-bottom: 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
    width: 100%;
  }
  
  .form-group label {
    font-weight: bold;
  }
  
  .form-group input {
    width: 100%;
    padding: 8px;
    margin-top: 5px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }

  button {
    width: 100%;
  }

  </style>