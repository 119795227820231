<template>
    <div class="app-page">
      <div class="terms">
        <h1>Stats</h1>
        <!-- Section for Stats -->
        <div class="dashboard-section stats-section">
                <!-- <div class="section-header">
                <h3><i class="fas fa-chart-line"></i> Your Stats</h3>
                </div> -->
                <div class="stats-grid">
                  <div class="stat-item">
                      <i class="fas fa-calendar-check stat-icon"></i>
                      <h4>{{ bookedRides.length }}</h4>
                      <p>Booked Rides</p>
                  </div>
                  <div class="stat-item">
                      <i class="fas fa-car stat-icon"></i>
                      <h4>{{ driverRides.length }}</h4>
                      <p>Upcoming Rides (Driver)</p>
                  </div>
                  <div class="stat-item">
                      <router-link to="/chat">
                        <i class="fas fa-envelope stat-icon"></i>
                        <h4>{{ recentChats.length }}</h4>
                        <p>New Messages</p>
                      </router-link>
                  </div>
                  <div class="stat-item">
                      <i class="fas fa-check-circle stat-icon"></i>
                      <h4>{{ completedRides.length }}</h4>
                      <p>Completed Rides</p>
                  </div>
                </div>
            </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
  // Incoming booked rides as passenger
  bookedRides: [
          { id: 1, title: "Ride to City Center", description: "On Oct 20 at 10:30 AM" },
          { id: 2, title: "Ride to Airport", description: "On Oct 22 at 6:00 AM" }
        ],
        
        // Incoming rides as driver
        driverRides: [
          { id: 3, title: "Pick-up at North Park", description: "On Oct 19 at 3:00 PM" },
          { id: 4, title: "Ride to Downtown", description: "On Oct 21 at 2:30 PM" }
        ],
        
        // Recent chat messages
        recentChats: [
          { id: 1, sender: "John Doe", messageSnippet: "Hey, are you still available for the ride..." },
          { id: 2, sender: "Jane Smith", messageSnippet: "I need to update my pick-up location..." }
        ],
        
        // Completed rides for statistics
        completedRides: [
          { id: 5, title: "Completed Ride to Suburbs", description: "Completed on Oct 12" },
          { id: 6, title: "Completed Airport Ride", description: "Completed on Oct 15" }
        ]        
      };
    },
    watch: {
      $route() {
      // Push a new query parameter when the route changes
      this.$router.push({ query: { menuType: 'app' } });
    },
    },
    mounted () {
      this.$router.push({ query: { menuType: 'app' } });
    }
  };
  </script>
  
  <style scoped>
  .terms {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
  }
  .dashboard-page {
    max-width: 600px;
    margin: 30px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
  }
  
  h2 {
    color: #8ACE00;
    margin-bottom: 20px;
  }
  
  .dashboard-section {
    margin-bottom: 40px;
  }
  
  .section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .section-header h3 {
    color: #8ACE00;
  }
  
  .section-action {
    color: #8ACE00;
    cursor: pointer;
  }
  
  .rides-list, .chat-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .ride-item, .chat-item {
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .ride-item:hover, .chat-item:hover {
    background-color: #e0e0e0;
  }
  
  .ride-icon, .chat-icon {
    margin-right: 15px;
    font-size: 24px;
    color: #8ACE00;
  }
  
  .ride-info, .chat-info {
    flex-grow: 1;
  }
  
  .stats-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  
  .stat-item {
    background-color: #e0e0e0;
    padding: 20px;
    text-align: center;
    border-radius: 8px;
    transition: background-color 0.3s;
  }
  
  .stat-item:hover {
    background-color: #d0d0d0;
  }
  
  .stat-icon {
    font-size: 28px;
    color: #8ACE00;
    margin-bottom: 10px;
  }
  
  h4 {
    margin-bottom: 5px;
  }
  </style>