<template>
    <div class="app-page">
      <div class="ride-details-page">
        <h2>Ride Details</h2>
    
        <!-- Ride Info Section -->
        <div class="ride-info">
          <h3><i class="fas fa-map-marker-alt"></i> {{ ride.title }}</h3>
          <p><i class="fas fa-calendar-alt"></i> Date: {{ ride.date }}</p>
          <p><i class="fas fa-clock"></i> Time: {{ ride.time }}</p>
          <p><i class="fas fa-map"></i> Start Location: {{ ride.startLocation }}</p>
          <p><i class="fas fa-map"></i> Destination: {{ ride.destination }}</p>
          <p><i class="fas fa-users"></i> Seats Available: {{ ride.availableSeats }}</p>
          <p><i class="fas fa-dollar-sign"></i> Price per Seat: ${{ ride.pricePerSeat }}</p>
        </div>
    
        <!-- Driver Info Section -->
        <div class="driver-info">
          <h3><i class="fas fa-user-circle"></i> Driver Information</h3>
          <p><i class="fas fa-user"></i> Name: {{ driver.name }}</p>
          <p><i class="fas fa-star"></i> Rating: {{ driver.rating }} / 5</p>
          <p><i class="fas fa-car"></i> Car: {{ driver.carMake }} {{ driver.carModel }}</p>
          <p><i class="fas fa-phone"></i> Contact: {{ driver.contactNumber }}</p>
        </div>
    
        <!-- Action Buttons -->
        <div class="ride-actions">
          <button @click="cancelRide" class="cancel-button">
            <i class="fas fa-times-circle"></i> Cancel Ride
          </button>
          <button @click="messageDriver" class="message-button">
            <i class="fas fa-comment-dots"></i> Message Driver
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "RideDetailsPage",
    data() {
      return {
        ride: {
          id: 1,
          title: "Ride to City Center",
          date: "2024-10-21",
          time: "10:30 AM",
          startLocation: "1234 Elm Street",
          destination: "City Center",
          availableSeats: 3,
          pricePerSeat: 15
        },
        driver: {
          name: "John Doe",
          rating: 4.5,
          carMake: "Toyota",
          carModel: "Corolla",
          contactNumber: "555-1234"
        }
      };
    },
    methods: {
      cancelRide() {
        // Logic for canceling the ride
        alert("Ride canceled!");
        this.$router.push({ name: "DashboardPage" });
      },
      messageDriver() {
        // Logic for messaging the driver
        this.$router.push({ name: "ChatPage", params: { chatId: this.driver.contactNumber } });
      }
    },
    watch: {
      $route() {
      // Push a new query parameter when the route changes
      this.$router.push({ query: { menuType: 'app' } });
    },
    },
    mounted () {
      this.$router.push({ query: { menuType: 'app' } });
    }
  };
  </script>
  
  <style scoped>
  .ride-details-page {
    max-width: 600px;
    margin: 30px auto;
    padding: 20px;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
  }
  
  h2 {
    color: #8ACE00;
    margin-bottom: 20px;
  }
  
  .ride-info, .driver-info {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    margin-bottom: 20px;
  }
  
  .ride-info h3, .driver-info h3 {
    color: #8ACE00;
    margin-bottom: 10px;
  }
  
  .ride-info p, .driver-info p {
    margin: 8px 0;
    color: #555;
  }
  
  .ride-actions {
    display: flex;
    gap: 15px;
  }
  
  .cancel-button, .message-button {
    padding: 12px 20px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .cancel-button {
    background-color: #FF6B6B;
    color: white;
  }
  
  .message-button {
    background-color: #8ACE00;
    color: white;
  }
  
  .cancel-button:hover {
    background-color: #FF5A5A;
  }
  
  .message-button:hover {
    background-color: #76B200;
  }
  
  i {
    margin-right: 8px;
  }
  </style>
  