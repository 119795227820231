<template>

    <!-- footer
    ================================================== -->
    <footer class="s-footer footer">


<div class="row footer__bottom">

    <div class="footer__about col-nine tab-full left">

        <div class="footer__contact col-five mob-full">
            <h3 class='footer-logo'>
                <img class="footer-logo__img" src="/images/Spawn_logo_512px_white.png"/>
                <span class="footer-logo__text">Spawnaride</span>
            </h3>
            <h4>Nous Contacter</h4>
            <!-- <p>Phone: <a href="tel:18005555555">1-800-555-5555</a></p> -->
            <p>Besoin d'aide ou avez des questions ?<br> <br>
            <router-link class="btn btn--primary" to="/contact-us">Contact Us</router-link>
            </p>
            <ul class="footer__social">
                <h4>Social Medias</h4>
                <li><a target="_blank" href="https://www.facebook.com/profile.php?id=61567111434771"><i class="fab fa-facebook-f" aria-hidden="true"></i></a></li>
                <li><a target="_blank" class="custom-footer-social" href="https://x.com/Spawnaride"><img src="@/assets/socials/x-solid.svg" class="fab fa-custom" aria-hidden="true"/></a></li>
                <li>
                    <a target="_blank" class="custom-footer-social" href="https://www.tiktok.com/@Spawnaride/">
                        <img src="@/assets/socials/tiktok-brands-solid.svg" class="fab fa-custom" aria-hidden="true"/>
                    </a>
                </li>
                <li>
                    <a target="_blank" href="https://www.instagram.com/Spawnaride/">
                        <i class="fab fa-instagram" aria-hidden="true"></i>
                    </a>
                </li>
            </ul>
        </div>
        <div class="footer__contact col-seven mob-full">

            <h4>À Propos de Spawnaride</h4>

            <p>
            Spawnaride est l'application de covoiturage qui simplifie vos déplacements quotidiens. Notre mission est de rendre le transport plus accessible, économique et respectueux de l'environnement, tout en offrant une plateforme intuitive et sécurisée. Rejoignez-nous et profitez d'une expérience de covoiturage sans tracas, où que vous soyez.
            </p>


        </div>
    </div>

    <div class="col-three tab-full right end">
        <div class="row">
            <h4>Site links</h4>
            <ul class="footer__site-links">
                <li><a href="#home" class="smoothscroll">Introduction</a></li>
                <li><a href="#about" class="smoothscroll">À Propos</a></li>
                <li><a href="#features" class="smoothscroll">Fonctionnalités</a></li>
                <li><a href="#pricing" class="smoothscroll">Tarifs</a></li>
                <li><a href="#download" class="smoothscroll">Téléchargement</a></li>
                <li></li>
            </ul>
        </div>
    </div>

    <div class="col-full ss-copyright">
        <span>&copy; Spawnaride Inc. </span>
        <span><router-link to="/terms">Terms</router-link></span>
        <span><router-link to="/privacy">Privacy Policy</router-link></span>
    </div>

</div> <!-- end footer__bottom -->

<div class="go-top">
    <a class="smoothscroll" title="Back to Top" href="#top"></a>
</div>

</footer> <!-- end s-footer -->
</template>
  
<style scoped>
    .footer-logo {
        font-size: 32px;
        color: #fff;
        margin: 0 0 30px;
        color: #8ace00;
        min-width: 250px;
    }
    .footer-logo__img {
        width: 44px;
    }
    .footer-logo__text {
        top: -10px;
        left: 10px;
        position: relative;
    }
    .fa-custom {
        display: block;
        top: 10;
        top: 10px;
        margin: 0;
        padding: 10px;
        width: 33px;
        margin: 0px 0 0;
    }
    .custom-footer-social {
        position: relative;
        transform: translate(0, 12px);
    }
    .footer__social {
        margin: 10px 0 0;
    }
    .btn--primary:hover {
        color: #000;
    }
</style>