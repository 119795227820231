<template>
     <!-- home
    ================================================== -->
    <section id="home" class="s-home target-section" data-parallax="scroll" data-image-src="images/s2.webp" data-natural-width=3000 data-natural-height=2000 data-position-y=center>

<div class="shadow-overlay"></div>

<div class="home-content">

    <div class="row home-content__main">

        <div class="home-content__left">
            <h1>
              Covoiturage Révolutionnaire
            </h1>
            <form @submit.prevent="submitSearch" class="search-form bf-filter">
                <h2>Trouvez un covoiturage</h2>
                <div class="form-group">
                <label for="fromCity">From City:</label>
                <input
                    type="text"
                    v-model="fromCitySearch"
                    @input="filterFromCities"
                    id="fromCity"
                    placeholder="Enter departure city"
                    class="input"
                    required
                />
                <ul v-if="filteredFromCities.length" class="suggestions">
                    <li v-for="city in filteredFromCities" :key="city.city_code" @click="selectFromCity(city)" class="suggestion-item">
                    {{ city.en.city }} ({{ city.en.province }})
                    </li>
                </ul>
                </div>
        
                <div class="form-group">
                <label for="toCity">To City:</label>
                <input
                    type="text"
                    v-model="toCitySearch"
                    @input="filterToCities"
                    id="toCity"
                    placeholder="Enter destination city"
                    class="input"
                    required
                />
                <ul v-if="filteredToCities.length" class="suggestions">
                    <li v-for="city in filteredToCities" :key="city.city_code" @click="selectToCity(city)" class="suggestion-item">
                    {{ city.en.city }} ({{ city.en.province }})
                    </li>
                </ul>
                </div>
        
                <div class="form-group">
                <label for="dateFrom">From Date:</label>
                <input type="date" v-model="dateFrom" id="dateFrom" class="input" required />
                </div>
                <!--   
                <div class="form-group">
                <label for="dateTo">To Date:</label>
                <input type="date" v-model="dateTo" id="dateTo" class="input" required />
                </div> -->
        
                <button type="submit" class="btn btn--primary">Search</button>
            </form>
            <!-- <h3>
              Incrivez-vous à Spawnaride et bénéficiez de 5 réservations gratuites ainsi qu'une inscription gratuite pendant un an ! Ne manquez pas cette offre exclusive pour simplifier vos trajets et économiser dès le lancement de l'applicatoin.
            </h3>

            <div class="home-content__btn-wrap" v-if="!preregistered">
              <router-link class="btn btn--primary home-content__btn" to="register">
                    Inscription
              </router-link>
            </div>
            <div class="home-content__btn-wrap" v-else>
              <h2 class="text-primary">Merci pour votre préinscription !</h2>
              <p class="text-white">Vous recevrez bientôt des informations et des mises à jour sur nos prochaines étapes.</p>
            </div> -->
        </div> <!-- end home-content__left-->

        <div class="home-content__right">
            <img src="images/hero-app-screens-800.png" srcset="images/hero-app-screens-800.png 1x, images/hero-app-screens-1600.png 2x">
        </div> <!-- end home-content__right -->

    </div> <!-- end home-content__main -->

    <ul class="home-content__social">
        <li><a target="_blank" href="https://www.facebook.com/profile.php?id=61567111434771">Facebook</a></li>
        <li><a target="_blank" href="https://x.com/spawnaride">X</a></li>
        <li><a target="_blank" href="https://www.tiktok.com/@spawnaride/">TikTok</a></li>
        <li><a target="_blank" href="https://www.instagram.com/spawnaride/">Instagram</a></li>
    </ul>

</div> <!-- end home-content -->

<a href="#about" class="home-scroll smoothscroll">
    <span class="home-scroll__text">Scroll</span>
    <span class="home-scroll__icon"></span>
</a> 

</section> <!-- end s-home -->


<!-- about
================================================== -->
<section id="about" class="s-about target-section">

<div class="row section-header has-bottom-sep" data-aos="fade-up">
    <div class="col-full">
        <h1 class="display-1">
            La solution idéale pour un covoiturage simple et abordable! 
        </h1>
        <p class="lead">
          Découvrez l'application qui transforme vos trajets. Avec une interface intuitive et des fonctionnalités innovantes, Spawnaride est conçue pour simplifier vos déplacements et optimiser votre expérience de covoiturage.
        </p>
    </div>
</div> <!-- end section-header -->

<div class="row wide about-desc" data-aos="fade-up">

    <div class="col-full slick-slider about-desc__slider">

        <div class="about-desc__slide">
            <h3 class="item-title">Intuitive.</h3>

            <p>Naviguez facilement grâce à une interface conviviale. Trouvez et réservez un trajet en quelques clics, rendant le covoiturage accessible à tous, peu importe leur niveau de compétence technologique.
            </p>
        </div>  <!-- end about-desc__slide -->

        <div class="about-desc__slide">
            <h3 class="item-title">Intelligente.</h3>

            <p>Spawnaride utilise des algorithmes avancés pour vous connecter rapidement avec des conducteurs ou passagers compatibles, vous offrant ainsi les meilleures options de trajet selon vos préférences et horaires.
            </p>
        </div>  <!-- end about-desc__slide -->

        <div class="about-desc__slide">
            <h3 class="item-title">Puissante.</h3>

            <p>
              Avec des fonctionnalités complètes, telles que la planification des trajets, les évaluations des utilisateurs, et des notifications en temps réel, l'application vous assure un contrôle total sur vos déplacements.
            </p>
        </div>  <!-- end about-desc__slide -->

        <div class="about-desc__slide">
            <h3 class="item-title">Sécurisée.</h3>

            <p>Votre sécurité est notre priorité. Spawnaride inclut des vérifications des utilisateurs, un cryptage des données, et une assistance en cas de problème pendant votre trajet, vous garantissant une expérience de covoiturage sans souci.
            </p>
        </div>  <!-- end about-desc__slide -->

    </div> <!-- end about-desc__slider -->
    
</div> <!-- end about-desc -->

</section> <!-- end s-about -->


<!-- about-how
================================================== -->
<section id="about-how" class="s-about-how">

<div class="row"  style="display: none;">
   <div class="col-full video-bg" data-aos="fade-up">

        <div class="shadow-overlay"></div>

        <a class="btn-video" href="https://player.vimeo.com/video/14592941?color=00a650&title=0&byline=0&portrait=0" data-lity>
            <span class="video-icon"></span>
        </a>

        <div class="stats">
            <div class="item-stats">
                <span class="item-stats__num">
                    3.1M
                </span>
                <span class="item-stats__title">
                    Downloads
                </span>
            </div> <!-- end item-stats -->
            <div class="item-stats">
                <span class="item-stats__num">
                    24K
                </span>
                <span class="item-stats__title">
                    Positive Reviews
                </span>
            </div> <!-- end item-stats -->
        </div>
   </div> <!-- end video-bg -->
</div>

<div class="row process-wrap" style="margin-top: 0;padding-top: 12rem;;">

    <h2 class="display-2 text-center" data-aos="fade-up">Comment Fonctionne l'Application ?</h2>

    <div class="process" data-aos="fade-up">
        <div class="process__steps block-1-2 block-tab-full group">

            <div class="col-block step">
                <h3 class="item-title">(Pré) Inscription</h3>
                <p>Créez votre compte en quelques minutes avec une inscription simple et rapide. Profitez de l'offre de préinscription pour bénéficier d'avantages exclusifs comme des réservations gratuites.</p>
            </div>

            <div class="col-block step">
                <h3 class="item-title">Création d'un Trajet</h3>
                <p>Publiez ou recherchez un trajet en indiquant votre point de départ, destination et horaires. L'application connecte automatiquement les conducteurs et passagers pour optimiser les trajets partagés.</p>
            </div>
       
            <div class="col-block step">
                <h3 class="item-title">Réservation</h3>
                <p>Réservez votre place en quelques clics et recevez une confirmation instantanée. Vous pouvez gérer vos réservations directement depuis l'application.</p> 
            </div>

            <div class="col-block step">
                <h3 class="item-title">Voyagez</h3>
                <p>Rendez-vous à votre point de départ et voyagez en toute sérénité. Spawnaride s'assure que vos trajets sont sûrs, pratiques et économiques.</p> 
            </div>  

        </div> <!-- end process__steps -->
   </div> <!-- end process -->
</div> <!-- end about-how -->

</section> <!-- end s-about-how -->


<!-- features
================================================== -->
<section id="features" class="s-features target-section">

<div class="row section-header has-bottom-sep" data-aos="fade-up">
    <div class="col-full">
        <h1 class="display-1">
            Chargée de Fonctionnalités Que Vous Allez Adorer
        </h1>
        <p class="lead">
          Explorez une application innovante, conçue pour rendre vos trajets plus pratiques et agréables. Profitez d'une multitude de fonctionnalités qui simplifient chaque étape de vos déplacements.
        </p>
    </div>
</div> <!-- end section-header -->

<div class="row features block-1-3 block-m-1-2">

    <div class="col-block item-feature" data-aos="fade-up">
        <div class="item-feature__icon">
            <i class="icon-car"></i>
        </div>
        <div class="item-feature__text">
            <h3 class="item-title">Planification de Trajets</h3>
            <p>Organisez vos trajets à l'avance ou trouvez des covoiturages de dernière minute facilement grâce à un moteur de recherche rapide et intuitif.
            </p>
        </div>
    </div>

    <div class="col-block item-feature" data-aos="fade-up">
        <div class="item-feature__icon">
            <i class="icon-shield"></i>
        </div>
        <div class="item-feature__text">
            <h3 class="item-title">Sécuritaire</h3>
            <p>Spawnaride met la sécurité au premier plan, avec une vérification des utilisateurs et des conducteurs, ainsi qu'une surveillance continue pour garantir des trajets sûrs.
            </p>
        </div>
    </div>

    <div class="col-block item-feature" data-aos="fade-up">
        <div class="item-feature__icon">
            <i class="icon-chat"></i>
        </div>  
        <div class="item-feature__text">
            <h3 class="item-title">Chat en Direct</h3>
            <p>Communiquez avec les conducteurs et passagers via une messagerie intégrée pour coordonner facilement vos trajets et partager des informations importantes en temps réel.
            </p>
        </div>
    </div>

    <div class="col-block item-feature" data-aos="fade-up">
        <div class="item-feature__icon">
            <i class="icon-card"></i>
        </div>
        <div class="item-feature__text">
            <h3 class="item-title">Paiements Sécurisés</h3>
            <p>Effectuez vos paiements directement via l'application en toute sécurité grâce à des méthodes de paiement protégées et simples à utiliser.</p>
        </div>
    </div>

    <div class="col-block item-feature" data-aos="fade-up">
        <div class="item-feature__icon">
            <i class="icon-map"></i>
        </div>
        <div class="item-feature__text">
            <h3 class="item-title">Optimisation des Trajets</h3>
            <p>L'application vous aide à trouver les trajets les plus rapides et économiques en analysant automatiquement les options disponibles en fonction de vos préférences.</p>
        </div>
    </div>

    <div class="col-block item-feature" data-aos="fade-up">
        <div class="item-feature__icon">
            <i class="icon-star"></i>
        </div>
        <div class="item-feature__text">
            <h3 class="item-title">Évaluations et Avis</h3>
            <p>Partagez et consultez les avis des utilisateurs pour faire des choix éclairés sur vos trajets. Les évaluations aident à maintenir une communauté de confiance.</p>
        </div>
    </div>

</div> <!-- end features -->

<div class="testimonials-wrap" data-aos="fade-up" style="display: none;">

    <div class="row">
        <div class="col-full testimonials-header">
            <h2 class="display-2">Our Users Love Our App!</h2>
        </div>
    </div>

    <div class="row testimonials">

        <div class="col-full slick-slider testimonials__slider">

            <div class="testimonials__slide">
                <img src="images/avatars/user-03.jpg" alt="Author image" class="testimonials__avatar">
                
                <p>Qui ipsam temporibus quisquam velMaiores eos cumque distinctio nam accusantium ipsum. 
                Laudantium quia consequatur molestias delectus culpa facere hic dolores aperiam. Accusantium praesentium corpori.</p>

                <div class="testimonials__author">
                    <span class="testimonials__name">Naruto Uzumaki</span>
                    <a href="#0" class="testimonials__link">@narutouzumaki</a>
                </div>
            </div> <!-- end testimonials__slide -->

            <div class="testimonials__slide">
                <img src="images/avatars/user-05.jpg" alt="Author image" class="testimonials__avatar">
                
                <p>Excepturi nam cupiditate culpa doloremque deleniti repellat. Veniam quos repellat voluptas animi adipisci.
                Nisi eaque consequatur. Quasi voluptas eius distinctio. Atque eos maxime. Qui ipsam temporibus quisquam vel.</p>
                
                <div class="testimonials__author">
                    <span class="testimonials__name">Sasuke Uchiha</span>
                    <a href="#0" class="testimonials__link">@sasukeuchiha</a>
                </div>
            </div> <!-- end testimonials__slide -->

            <div class="testimonials__slide">
                <img src="images/avatars/user-01.jpg" alt="Author image" class="testimonials__avatar">
                
                <p>Repellat dignissimos libero. Qui sed at corrupti expedita voluptas odit. Nihil ea quia nesciunt. Ducimus aut sed ipsam.  
                Autem eaque officia cum exercitationem sunt voluptatum accusamus. Quasi voluptas eius distinctio.</p>

                <div class="testimonials__author">
                    <span class="testimonials__name">Shikamaru Nara</span>
                    <a href="#0" class="testimonials__link">@shikamarunara</a>
                </div>
            </div> <!-- end testimonials__slide -->
            
        </div> <!-- end testimonials__slider -->

    </div> <!-- end testimonials -->

</div> <!-- end testimonials-wrap -->

</section> <!-- end s-features -->


<!-- pricing
================================================== -->
<section id="pricing" class="s-pricing target-section">

<div class="row section-header align-center" data-aos="fade-up">
    <div class="col-full">
        <h1 class="display-1">
          Nos Tarifs<br>Simples et Clairs.
        </h1>
        <p class="lead">
          Découvrez nos options de tarification conçues pour s'adapter à tous les utilisateurs. Profitez de notre modèle transparent et sans surprise pour vos trajets de covoiturage.
        </p>
    </div>
</div> <!-- end section-header -->

<div class="row plans block-1-2 block-m-1-2 block-tab-full stack">

    <div class="col-block item-plan" data-aos="fade-up">
        <div class="item-plan__block"> 

            <div class="item-plan__top-part">
                <h3 class="item-plan__title ">Préinscription</h3>
                <p class="item-plan__price">$0</p>
                <p class="item-plan__per">1 an</p>
            </div>

            <div class="item-plan__bottom-part">
                <ul class="item-plan__features">
                    <li>5 Réservations <span>Gratuits</span></li>
                    <li>Accès à la Plateform</li>
                    <li>Assistance Clientèle</li>
                    <li>Inscription Gratuite pour 1 An</li>
                </ul>
                <router-link class="btn btn--primary home-content__btn" to="register">
                    Préinscription
              </router-link>
            </div>  
        
        </div>
    </div> <!-- end item-plan -->

    <div class="col-block item-plan item-plan--popular" data-aos="fade-up">
        <div class="item-plan__block"> 

            <div class="item-plan__top-part">
                <h3 class="item-plan__title black-text">Inscription</h3>
                <p class="item-plan__price black-text">7,50$</p>
                <p class="item-plan__per black-text">Par année</p>
            </div>

            <div class="item-plan__bottom-part  black-text">
                <ul class="item-plan__features  black-text">
                    <li style="color:red">0 Réservations <span>Gratuites</span></li>
                    <li>Accès à la Plateform</li>
                    <li>Assistance Clientèle</li>
                    <li>Inscription 7,50$ pour 1 An</li>
                </ul>
                <router-link class="btn btn--primary home-content__btn" to="register">
                    Inscription
                </router-link>
            </div>
        
        </div>
    </div> <!-- end item-plan -->

</div> <!-- end plans -->

</section> <!-- end s-pricing -->


<!-- download
================================================== -->
<section id="download" class="s-download target-section">

<div class="row section-header align-center" data-aos="fade-up">
    <div class="col-full">
        <h1 class="display-1 black-text">
            Rejoignez Notre Communauté
        </h1>
        <p class="lead black-text">
          Faites partie d'une communauté dynamique de covoiturage. Partagez vos expériences, bénéficiez d'avantages exclusifs et profitez d'un réseau de conducteurs et passagers fiables.
        </p>
    </div>
</div> <!-- end section-header -->

<div class="row">
    <div class="col-full text-center">
        <ul class="download-badges" data-aos="fade-up">
        <p class="lead black-text">
            Utilisez l'application Web entre temps.
        </p>
        <router-link class="btn" to="/register">App Web</router-link>
        </ul>
        <ul class="download-badges" data-aos="fade-up">
            <p class="lead black-text">
            bientôt disponible sur app store google store.
        </p>
        <li><a href="#home" title="" class="smoothscroll badge-appstore">App Store</a></li>
        <li><a href="#home" title="" class="smoothscroll badge-googleplay">Play Store</a></li>
        </ul>
    </div> 
</div>

<div class="row download-bottom-image" data-aos="fade-up">
    <img src="images/app-screen-1400.png" style="display:none"
        srcset="images/app-screen-600.png 600w, 
                images/app-screen-1400.png 1400w, 
                images/app-screen-2800.png 2800w" 
        sizes="(max-width: 2800px) 100vw, 2800px" 
        alt="App Screenshots"> 
</div>

</section> <!-- end s-download -->

</template>

<script>
export default {
  name: 'LandingPage',
  mounted () {
  },
  data() {
    return {
        cities: [
            // Quebec Cities
            {
                city_code: 'MTL',
                province_code: 'QC',
                fr: { city: 'Montréal', province: 'Québec' },
                en: { city: 'Montreal', province: 'Quebec' }
            },
            {
                city_code: 'LAV',
                province_code: 'QC',
                fr: { city: 'Laval', province: 'Québec' },
                en: { city: 'Laval', province: 'Quebec' }
            },
            {
                city_code: 'QUE',
                province_code: 'QC',
                fr: { city: 'Québec', province: 'Québec' },
                en: { city: 'Quebec City', province: 'Quebec' }
            },
            {
                city_code: 'GAT',
                province_code: 'QC',
                fr: { city: 'Gatineau', province: 'Québec' },
                en: { city: 'Gatineau', province: 'Quebec' }
            },
            {
                city_code: 'LON',
                province_code: 'QC',
                fr: { city: 'Longueuil', province: 'Québec' },
                en: { city: 'Longueuil', province: 'Quebec' }
            },
            {
                city_code: 'SHE',
                province_code: 'QC',
                fr: { city: 'Sherbrooke', province: 'Québec' },
                en: { city: 'Sherbrooke', province: 'Quebec' }
            },
            {
                city_code: 'TR',
                province_code: 'QC',
                fr: { city: 'Trois-Rivières', province: 'Québec' },
                en: { city: 'Trois-Rivières', province: 'Quebec' }
            },
            {
                city_code: 'SAG',
                province_code: 'QC',
                fr: { city: 'Saguenay', province: 'Québec' },
                en: { city: 'Saguenay', province: 'Quebec' }
            },

            // Ontario Cities
            {
                city_code: 'TOR',
                province_code: 'ON',
                fr: { city: 'Toronto', province: 'Ontario' },
                en: { city: 'Toronto', province: 'Ontario' }
            },
            {
                city_code: 'KNG',
                province_code: 'ON',
                fr: { city: 'Kingston', province: 'Ontario' },
                en: { city: 'Kingston', province: 'Ontario' }
            },
            {
                city_code: 'OTT',
                province_code: 'ON',
                fr: { city: 'Ottawa', province: 'Ontario' },
                en: { city: 'Ottawa', province: 'Ontario' }
            },
            {
                city_code: 'MIS',
                province_code: 'ON',
                fr: { city: 'Mississauga', province: 'Ontario' },
                en: { city: 'Mississauga', province: 'Ontario' }
            },
            {
                city_code: 'BRA',
                province_code: 'ON',
                fr: { city: 'Brampton', province: 'Ontario' },
                en: { city: 'Brampton', province: 'Ontario' }
            },
            {
                city_code: 'LON',
                province_code: 'ON',
                fr: { city: 'London', province: 'Ontario' },
                en: { city: 'London', province: 'Ontario' }
            },
            {
                city_code: 'KIC',
                province_code: 'ON',
                fr: { city: 'Kitchener', province: 'Ontario' },
                en: { city: 'Kitchener', province: 'Ontario' }
            },
            {
                city_code: 'WIN',
                province_code: 'ON',
                fr: { city: 'Windsor', province: 'Ontario' },
                en: { city: 'Windsor', province: 'Ontario' }
            },
            {
                city_code: 'NIAG',
                province_code: 'ON',
                fr: { city: 'Niagara Falls', province: 'Ontario' },
                en: { city: 'Niagara Falls', province: 'Ontario' }
            },
            {
            "city_code": "VAN",
            "province_code": "BC",
            "fr": { "city": "Vancouver", "province": "Colombie-Britannique" },
            "en": { "city": "Vancouver", "province": "British Columbia" }
            },
            {
            "city_code": "NOU",
            "province_code": "BC",
            "fr": { "city": "North Vancouver", "province": "Colombie-Britannique" },
            "en": { "city": "North Vancouver", "province": "British Columbia" }
            },
            {
            "city_code": "SOU",
            "province_code": "BC",
            "fr": { "city": "South Vancouver", "province": "Colombie-Britannique" },
            "en": { "city": "South Vancouver", "province": "British Columbia" }
            },
            {
            "city_code": "WES",
            "province_code": "BC",
            "fr": { "city": "West Vancouver", "province": "Colombie-Britannique" },
            "en": { "city": "West Vancouver", "province": "British Columbia" }
            },
            {
            "city_code": "RIC",
            "province_code": "BC",
            "fr": { "city": "Richmond", "province": "Colombie-Britannique" },
            "en": { "city": "Richmond", "province": "British Columbia" }
            },
            {
            "city_code": "BUR",
            "province_code": "BC",
            "fr": { "city": "Burnaby", "province": "Colombie-Britannique" },
            "en": { "city": "Burnaby", "province": "British Columbia" }
            },
            {
            "city_code": "DEL",
            "province_code": "BC",
            "fr": { "city": "Delta", "province": "Colombie-Britannique" },
            "en": { "city": "Delta", "province": "British Columbia" }
            },
            {
            "city_code": "SUR",
            "province_code": "BC",
            "fr": { "city": "Surrey", "province": "Colombie-Britannique" },
            "en": { "city": "Surrey", "province": "British Columbia" }
            },
            {
            "city_code": "LAD",
            "province_code": "BC",
            "fr": { "city": "Ladner", "province": "Colombie-Britannique" },
            "en": { "city": "Ladner", "province": "British Columbia" }
            },
            {
            "city_code": "POR",
            "province_code": "BC",
            "fr": { "city": "Port Coquitlam", "province": "Colombie-Britannique" },
            "en": { "city": "Port Coquitlam", "province": "British Columbia" }
            },
            {
            "city_code": "COQ",
            "province_code": "BC",
            "fr": { "city": "Coquitlam", "province": "Colombie-Britannique" },
            "en": { "city": "Coquitlam", "province": "British Columbia" }
            },
            {
            "city_code": "MAP",
            "province_code": "BC",
            "fr": { "city": "Maple Ridge", "province": "Colombie-Britannique" },
            "en": { "city": "Maple Ridge", "province": "British Columbia" }
            },
            {
            "city_code": "LANG",
            "province_code": "BC",
            "fr": { "city": "Langley", "province": "Colombie-Britannique" },
            "en": { "city": "Langley", "province": "British Columbia" }
            },
            {
            "city_code": "PITT",
            "province_code": "BC",
            "fr": { "city": "Pitt Meadows", "province": "Colombie-Britannique" },
            "en": { "city": "Pitt Meadows", "province": "British Columbia" }
            },
            {
            "city_code": "ABO",
            "province_code": "BC",
            "fr": { "city": "Abbotsford", "province": "Colombie-Britannique" },
            "en": { "city": "Abbotsford", "province": "British Columbia" }
            },
            {
            "city_code": "CAL",
            "province_code": "AB",
            "fr": { "city": "Calgary", "province": "Alberta" },
            "en": { "city": "Calgary", "province": "Alberta" }
            },
            {
            "city_code": "EDM",
            "province_code": "AB",
            "fr": { "city": "Edmonton", "province": "Alberta" },
            "en": { "city": "Edmonton", "province": "Alberta" }
            },
            {
            "city_code": "RED",
            "province_code": "AB",
            "fr": { "city": "Red Deer", "province": "Alberta" },
            "en": { "city": "Red Deer", "province": "Alberta" }
            },
            {
            "city_code": "LAC",
            "province_code": "AB",
            "fr": { "city": "Lacombe", "province": "Alberta" },
            "en": { "city": "Lacombe", "province": "Alberta" }
            },
            {
            "city_code": "LETH",
            "province_code": "AB",
            "fr": { "city": "Lethbridge", "province": "Alberta" },
            "en": { "city": "Lethbridge", "province": "Alberta" }
            },
            {
            "city_code": "AIR",
            "province_code": "AB",
            "fr": { "city": "Airdrie", "province": "Alberta" },
            "en": { "city": "Airdrie", "province": "Alberta" }
            },
            {
            "city_code": "MED",
            "province_code": "AB",
            "fr": { "city": "Medicine Hat", "province": "Alberta" },
            "en": { "city": "Medicine Hat", "province": "Alberta" }
            },
            {
            "city_code": "COLE",
            "province_code": "AB",
            "fr": { "city": "Cold Lake", "province": "Alberta" },
            "en": { "city": "Cold Lake", "province": "Alberta" }
            },
            {
            "city_code": "GRAN",
            "province_code": "AB",
            "fr": { "city": "Grand Prairie", "province": "Alberta" },
            "en": { "city": "Grande Prairie", "province": "Alberta" }
            },
            {
            "city_code": "BEA",
            "province_code": "AB",
            "fr": { "city": "Fort McMurray", "province": "Alberta" },
            "en": { "city": "Fort McMurray", "province": "Alberta" }
            },
            {
            "city_code": "STAL",
            "province_code": "AB",
            "fr": { "city": "St. Albert", "province": "Alberta" },
            "en": { "city": "St. Albert", "province": "Alberta" }
            },
            {
            "city_code": "OKOT",
            "province_code": "AB",
            "fr": { "city": "Okotoks", "province": "Alberta" },
            "en": { "city": "Okotoks", "province": "Alberta" }
            },
            {
            "city_code": "COCHR",
            "province_code": "AB",
            "fr": { "city": "Cochrane", "province": "Alberta" },
            "en": { "city": "Cochrane", "province": "Alberta" }
            },
            {
            "city_code": "DRUM",
            "province_code": "AB",
            "fr": { "city": "Drumheller", "province": "Alberta" },
            "en": { "city": "Drumheller", "province": "Alberta" }
            },
            {
            "city_code": "BRO",
            "province_code": "AB",
            "fr": { "city": "Brooks", "province": "Alberta" },
            "en": { "city": "Brooks", "province": "Alberta" }
            },
            {
            "city_code": "REG",
            "province_code": "SK",
            "fr": { "city": "Regina", "province": "Saskatchewan" },
            "en": { "city": "Regina", "province": "Saskatchewan" }
            },
            {
            "city_code": "SASK",
            "province_code": "SK",
            "fr": { "city": "Saskatoon", "province": "Saskatchewan" },
            "en": { "city": "Saskatoon", "province": "Saskatchewan" }
            },
            {
            "city_code": "PRIN",
            "province_code": "SK",
            "fr": { "city": "Prince Albert", "province": "Saskatchewan" },
            "en": { "city": "Prince Albert", "province": "Saskatchewan" }
            },
            {
            "city_code": "MOOSE",
            "province_code": "SK",
            "fr": { "city": "Moose Jaw", "province": "Saskatchewan" },
            "en": { "city": "Moose Jaw", "province": "Saskatchewan" }
            },
            {
            "city_code": "SWIFT",
            "province_code": "SK",
            "fr": { "city": "Swift Current", "province": "Saskatchewan" },
            "en": { "city": "Swift Current", "province": "Saskatchewan" }
            },
            {
            "city_code": "YORK",
            "province_code": "SK",
            "fr": { "city": "Yorkton", "province": "Saskatchewan" },
            "en": { "city": "Yorkton", "province": "Saskatchewan" }
            },
            {
            "city_code": "ESTEV",
            "province_code": "SK",
            "fr": { "city": "Estevan", "province": "Saskatchewan" },
            "en": { "city": "Estevan", "province": "Saskatchewan" }
            },
            {
            "city_code": "NIP",
            "province_code": "SK",
            "fr": { "city": "Nipawin", "province": "Saskatchewan" },
            "en": { "city": "Nipawin", "province": "Saskatchewan" }
            },
            {
            "city_code": "HUMB",
            "province_code": "SK",
            "fr": { "city": "Humboldt", "province": "Saskatchewan" },
            "en": { "city": "Humboldt", "province": "Saskatchewan" }
            },
            {
            "city_code": "WAT",
            "province_code": "SK",
            "fr": { "city": "Watrous", "province": "Saskatchewan" },
            "en": { "city": "Watrous", "province": "Saskatchewan" }
            },
            {
            "city_code": "SHELL",
            "province_code": "SK",
            "fr": { "city": "Shellbrook", "province": "Saskatchewan" },
            "en": { "city": "Shellbrook", "province": "Saskatchewan" }
            },
            {
            "city_code": "LLOY",
            "province_code": "SK",
            "fr": { "city": "Lloydminster", "province": "Saskatchewan" },
            "en": { "city": "Lloydminster", "province": "Saskatchewan" }
            },
            {
            "city_code": "BATTLE",
            "province_code": "SK",
            "fr": { "city": "Battleford", "province": "Saskatchewan" },
            "en": { "city": "Battleford", "province": "Saskatchewan" }
            },
            {
            "city_code": "MART",
            "province_code": "SK",
            "fr": { "city": "Martensville", "province": "Saskatchewan" },
            "en": { "city": "Martensville", "province": "Saskatchewan" }
            },
            {
            "city_code": "WPG",
            "province_code": "MB",
            "fr": { "city": "Winnipeg", "province": "Manitoba" },
            "en": { "city": "Winnipeg", "province": "Manitoba" }
            },
            {
            "city_code": "BRAND",
            "province_code": "MB",
            "fr": { "city": "Brandon", "province": "Manitoba" },
            "en": { "city": "Brandon", "province": "Manitoba" }
            },
            {
            "city_code": "STE",
            "province_code": "MB",
            "fr": { "city": "Steinbach", "province": "Manitoba" },
            "en": { "city": "Steinbach", "province": "Manitoba" }
            },
            {
            "city_code": "THOMP",
            "province_code": "MB",
            "fr": { "city": "Thompson", "province": "Manitoba" },
            "en": { "city": "Thompson", "province": "Manitoba" }
            },
            {
            "city_code": "DAUPH",
            "province_code": "MB",
            "fr": { "city": "Dauphin", "province": "Manitoba" },
            "en": { "city": "Dauphin", "province": "Manitoba" }
            },
            {
            "city_code": "FLIN",
            "province_code": "MB",
            "fr": { "city": "Flin Flon", "province": "Manitoba" },
            "en": { "city": "Flin Flon", "province": "Manitoba" }
            },
            {
            "city_code": "MORD",
            "province_code": "MB",
            "fr": { "city": "Morden", "province": "Manitoba" },
            "en": { "city": "Morden", "province": "Manitoba" }
            },
            {
            "city_code": "PORT",
            "province_code": "MB",
            "fr": { "city": "Portage la Prairie", "province": "Manitoba" },
            "en": { "city": "Portage la Prairie", "province": "Manitoba" }
            },
            {
            "city_code": "SELK",
            "province_code": "MB",
            "fr": { "city": "Selkirk", "province": "Manitoba" },
            "en": { "city": "Selkirk", "province": "Manitoba" }
            },
            {
            "city_code": "GIMLI",
            "province_code": "MB",
            "fr": { "city": "Gimli", "province": "Manitoba" },
            "en": { "city": "Gimli", "province": "Manitoba" }
            },
            {
            "city_code": "NEEP",
            "province_code": "MB",
            "fr": { "city": "Neepawa", "province": "Manitoba" },
            "en": { "city": "Neepawa", "province": "Manitoba" }
            },
            {
            "city_code": "WINNI",
            "province_code": "MB",
            "fr": { "city": "Winnipeg Beach", "province": "Manitoba" },
            "en": { "city": "Winnipeg Beach", "province": "Manitoba" }
            },
            {
            "city_code": "BIRT",
            "province_code": "MB",
            "fr": { "city": "Birtle", "province": "Manitoba" },
            "en": { "city": "Birtle", "province": "Manitoba" }
            },
            {
            "city_code": "CARB",
            "province_code": "MB",
            "fr": { "city": "Carberry", "province": "Manitoba" },
            "en": { "city": "Carberry", "province": "Manitoba" }
            }
  
        ],
        fromCitySearch: '',
        toCitySearch: '',
        filteredFromCities: [],
        filteredToCities: [],
        selectedFromCity: '',
        selectedToCity: '',
        dateFrom: this.getCurrentDate(), // Set default to today
        dateTo: '', // No default for To Date
        searchResults: [],
      email: '',
      preregistered: false,
      features: [
        { title: 'Partage Facile', description: 'Partagez vos trajets avec des amis et d\'autres utilisateurs.' },
        { title: 'Sécurisé', description: 'Des paiements sécurisés pour chaque réservation.' },
        { title: 'Multi-plateforme', description: 'Disponible sur iOS, Android et Web.' },
      ],
    };
  },
  methods: {
    formatDepartureTime(dateTime) {
        const departureDate = new Date(dateTime);
        const today = new Date();
        const tomorrow = new Date();
        tomorrow.setDate(today.getDate() + 1);
        
        if (departureDate.toDateString() === tomorrow.toDateString()) {
          return "Tomorrow at " + departureDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
        } else {
          return departureDate.toLocaleString(); // or any other format you prefer
        }
      },
      loadCities() {
        this.filteredFromCities = this.cities; // Initialize with all cities
        this.filteredToCities = this.cities; // Initialize with all cities
      },
    filterFromCities() {
        if (this.fromCitySearch) {
        this.filteredFromCities = this.cities.filter(city =>
            city.en.city.toLowerCase().includes(this.fromCitySearch.toLowerCase()) ||
            city.fr.city.toLowerCase().includes(this.fromCitySearch.toLowerCase())
        );
        } else {
        this.filteredFromCities = this.cities; // Reset to all cities if input is empty
        }
    },
    filterToCities() {
        if (this.toCitySearch) {
        this.filteredToCities = this.cities.filter(city =>
            city.en.city.toLowerCase().includes(this.toCitySearch.toLowerCase()) ||
            city.fr.city.toLowerCase().includes(this.toCitySearch.toLowerCase())
        );
        } else {
        this.filteredToCities = this.cities; // Reset to all cities if input is empty
        }
    },
    selectFromCity(city) {
        this.fromCitySearch = city.en.city; // Set the input field to the selected city
        this.selectedFromCity = city.en.city; // Store the selected city code
        this.filteredFromCities = []; // Clear suggestions
    },
    selectToCity(city) {
        this.toCitySearch = city.en.city; // Set the input field to the selected city
        this.selectedToCity = city.en.city; // Store the selected city code 
        this.filteredToCities = []; // Clear suggestions
    },
    getCurrentDate() {
        const today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
        const yyyy = today.getFullYear();
        return `${yyyy}-${mm}-${dd}`;
      },
    async preregister () {
      await this.sendPreRegistrationEmail(this.email);
      this.preregistered = true;
    },
    async submitSearch() {
        if (!this.selectedFromCity || !this.selectedToCity) {
          alert("Please select both from and to cities.");
          return;
        }
        try {
                this.$router.push({ 
                    name: "CarSharingList", 
                    query: 
                        { 
                            selectedToCity: this.selectedToCity, 
                            selectedFromCity: this.selectedFromCity,
                            dateFrom: this.dateFrom
                        } 
                    });
        } catch (error) {
          console.error("Error fetching search results:", error);
          alert("Failed to fetch search results. Please try again later.");
        } 
        // finally {}
      },
    async sendPreRegistrationEmail(email) {
      const url = 'https://api.spawnaride.com/pre-register'; // Remplacez par l'URL de votre API

      const data = {
          email: email
      };

      try {
          const response = await fetch(url, {
              method: 'POST', // Ou 'GET', selon votre API
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify(data),
          });

          if (!response.ok) {
              throw new Error('Erreur de réseau : ' + response.statusText);
          }

          const responseData = await response.json();
          console.log('Préinscription réussie :', responseData);
      } catch (error) {
          console.error('Erreur lors de l\'envoi de la préinscription :', error);
      }
  },
    submitPreinscription() {
      alert('Merci de vous être préinscrit !');
    },
  },
};
</script>
<style scoped>
    .bf-filter {
        background-color: #ffffff;
        padding: 1px 10px 10px;
        margin: 10px 0 0;
        border-radius: 10px;
    }
    .bf-filter input {
        width: 100%;
        border: 1px solid #b9b9b9;
    }
    .bf-filter a {
        width: 100%;
    }
   .item-plan__block {
        background: #f0f0f0;
   }

  .search-form {
    display: flex;
    flex-direction: column;
    padding: 0 30px 20px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    width: 100%;
    box-sizing: border-box;
  }
  
  .suggestions {
    list-style-type: none;
    padding: 0;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
  }
  
  .suggestion-item {
    padding: 10px;
    cursor: pointer;
  }
  
  .suggestion-item:hover {
    background-color: #f0f0f0;
  }
  
</style>