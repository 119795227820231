import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import './styles/main.scss'; // Import your SCSS file
import Notifications from '@kyvg/vue3-notification'

createApp(App)
  .use(Notifications)
  .use(router)
  .mount('#app');
