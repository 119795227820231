<template>
  <div class="app-page">
    <div class="chat-detail-page">
      <h1>Chat {{ chatUser.name }}</h1>

      <div class="chat-messages" ref="messagesContainer">
        ­<div class="message-wrapper" v-for="(message, index) in messages" :key="index">
        <div class="chat-messages__img" :style="'float:' + (message.is_from_me ? 'right; margin-left: 10px' : 'left; margin-right: 10px') + ';background-image: url(\'https://spawnaride.com/users-pictures/' + message.sender_img_url + '\')'"></div>
        <div
          class="message" :class="{ 'message-sent': message.is_from_me, 'message-received': !message.is_from_me }">
          <p>{{ message.participant_names }}</p>
          <p>{{ message.content }}</p>
          <span class="timestamp">{{ formatDate(message.created_at) }}</span>
        </div>
        </div>
      </div>


      <div class="chat-input">
        <input type="text" v-model="newMessage" placeholder="Type a message..." />
        <button @click="sendMessage">Send</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import Cookies from 'js-cookie';

export default {
  name: 'ChatDetail',
  data() {
    return {
      token: Cookies.get('authToken'),
      chatUser: {}, // User you're chatting with
      messages: [], // List of chat messages
      newMessage: '', // Text input for new message
      messagePolling: null, // Polling interval reference
    };
  },
  created() {
    const chatId = this.$route.params.chatId;
    this.fetchChatData(chatId);
    this.startMessagePolling();
  },
  mounted () {
    this.$router.push({ query: { menuType: 'app' } });
  },
  beforeUnmount() {
    clearInterval(this.messagePolling);
  },
  methods: {
    fetchChatData(chatId) {
      // Fetch chat user and initial messages
      this.chatUser = {
        id: chatId,
        name: ''
      };

      this.fetchMessages(); // Initial fetch for messages
    },
    async fetchMessages() {
      try {
        const response = await axios.get(`https://api.spawnaride.com/api/messages/${this.chatUser.id}`, {
          headers: {
            Authorization: `Bearer ${this.token}`,
          },
        });
        this.messages = response.data.messages;

        const unseenMessages = this.messages.filter((msg) => !msg.isSent && !msg.seen);
        if (unseenMessages.length > 0) {
          await this.markMessagesAsSeen(unseenMessages.map((msg) => msg.id));
        }

        this.scrollToBottom();
      } catch (error) {
        console.error('Error fetching messages:', error);
      }
    },
    async sendMessage() {
      if (this.newMessage.trim() === '') return;

      try {
        const response = await axios.post(`https://api.spawnaride.com/api/messages/`, 
        {
          content: this.newMessage,
          conversationId: this.chatUser.id
        },
        {
          headers: {
            Authorization: `Bearer ${this.token}`,
          }
        });
        this.messages.push(response.data.message);
        this.newMessage = '';
        this.scrollToBottom();
      } catch (error) {
        console.error('Error sending message:', error);
      }
    },
    async markMessagesAsSeen(messageIds) {
      try {
        await axios.post(`https://api.spawnaride.com/messages/mark-seen/${this.chatUser.id}`, { messageIds });
      } catch (error) {
        console.error('Error marking messages as seen:', error);
      }
    },
    startMessagePolling() {
      this.messagePolling = setInterval(this.fetchMessages, 5000); // Fetch every 5 seconds
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const messagesContainer = this.$refs.messagesContainer;
        messagesContainer.scrollTop = messagesContainer.scrollHeight;
      });
    },
    formatDate(timestamp) {
      return new Date(timestamp).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    },
  },
};
</script>

<style scoped>
.message-wrapper {
    width: 100%;
    display: inline-block;
}
.chat-detail-page {
  max-width: 600px;
  margin: auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.chat-messages {
  max-height: 400px;
  overflow-y: auto;
  margin-bottom: 20px;
}

.message {
  padding: 10px;
  margin: 10px 0;
  border-radius: 8px;
  max-width: 80%;
}
.chat-messages__img {
  width: 60px;
  height: 60px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: #111;
  border-radius: 25px;

}
.message-sent {
  background-color: #8ace00;
  color: #111;
  align-self: flex-end;
  max-width: 80%;
  min-width: 51%;
  float: right;
}

.message-received {
  background-color: #5f5f5f;
  color: #fff;
  align-self: flex-start;
  max-width: 80%;
  min-width: 51%;
  float: left;
}

.timestamp {
  font-size: 10px;
  color: #999;
}

.chat-input {
  display: flex;
  align-items: center;
}

.chat-input input {
  flex: 1;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.chat-input button {
  margin-left: 10px;
  padding: 10px;
  background-color: #8ace00;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}
</style>
