<template>
    <div class="app-page">
        <div class="delete-account">
            <h2>Supprimer votre compte</h2>
            <p>
                Êtes-vous sûr de vouloir supprimer votre compte? Cette action est irréversible
                et toutes vos données seront perdues. Veuillez lire attentivement les
                conséquences avant de continuer.
            </p>
            
            <div class="warning">
                <p>⚠️ Attention: Cette action supprimera toutes vos informations personnelles et historiques.</p>
            </div>
        
            <div class="confirmation">
                <label>
                <input type="checkbox" v-model="confirmDelete" />
                Je confirme que je veux supprimer mon compte.
                </label>
            </div>
        
            <button 
                :disabled="!confirmDelete" 
                @click="deleteAccount"
                class="delete-button"
            >
                Supprimer mon compte
            </button>
        
            <router-link to="/account" class="cancel-link">
                Annuler
            </router-link>
        </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        confirmDelete: false,
      };
    },
    methods: {
      deleteAccount() {
        // Logic to delete the account goes here
        // Example: Call an API to delete the user account
        console.log('Compte supprimé'); // Placeholder for account deletion logic
  
        // Redirect to the home page or login page after deletion
        this.$router.push('/');
      }
    },
    watch: {
      $route() {
      // Push a new query parameter when the route changes
      this.$router.push({ query: { menuType: 'app' } });
    },
    },
    mounted () {
      this.$router.push({ query: { menuType: 'app' } });
    }
  };
  </script>
  
  <style scoped>
  .delete-account {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f9f9f9;
  }
  
  .delete-button {
    background-color: #d9534f; /* Bootstrap danger color */
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .delete-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .cancel-link {
    display: block;
    margin-top: 10px;
    color: #007bff; /* Bootstrap primary color */
    text-decoration: none;
  }
  
  .cancel-link:hover {
    text-decoration: underline;
  }
  
  .warning {
    background-color: #ffdddd;
    padding: 10px;
    border: 1px solid #f5c6cb;
    margin: 15px 0;
  }
  </style>
  